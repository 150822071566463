import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createDocType = async (data) => {
  return await API.postMethod(ENDPOINT.doctype.create, true, data);
};

const getDocType = async () => {
  return await API.getMethod(ENDPOINT.doctype.get, true);
};

const getDocTypeById = async (id) => {
  return await API.getMethod(`${ENDPOINT.doctype.get}/${id}`, true);
};

const updateDocTypeyById = async (id, data) => {
  return await API.putMethod(`${ENDPOINT.doctype.update}/${id}`, true, data);
};

const deleteDocTypeById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.doctype.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createDocType,
  getDocType,
  getDocTypeById,
  updateDocTypeyById,
  deleteDocTypeById,
};
