import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import SampleDataTable from "Components/DataTables";
import RegionAPIs from "../../APIs/region";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";

const Region = () => {
  const [region, setRegion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMode, setModalMode] = useState("add"); // 'add' or 'edit'
  const [currentEditItem, setCurrentEditItem] = useState(null);

  // To open modal in add mode
  const handleShowAdd = () => {
    setModalMode("add");
    setCurrentEditItem(null);
    setShowModal(true);
  };

  // To open modal in edit mode
  const handleShowEdit = (item) => {
    setModalMode("edit");
    setCurrentEditItem(item);
    setShowModal(true);
  };

  const editRegion = async (data, resetForm) => {
    setIsLoading(true);
    try {
      // Assuming your API has an editDocTypeById method
      await RegionAPIs.updateRegionById(currentEditItem.id, data);
      toast.success("Region Updated Successfully.");
      setShowModal(false); // Close modal after submission
      getAllRegion(); // Refresh the list
    } catch (error) {
      toast.error("Failed to update Region.");
    }
    setIsLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Region Name. is required"),
  });

  const submitHandler = async (data, resetForm) => {
    setIsLoading(true);
    const res = await RegionAPIs.createRegion(data);
    if (res) {
      toast.success("Region Added Successfully.");
      resetForm({ values: "" });
      setShowModal(false); // Close modal after submission
      getAllRegion();
    }
    setIsLoading(false);
  };

  const getAllRegion = async () => {
    const region = await RegionAPIs.getRegion();
    if (region) {
      setRegion(region.data.data);
    }
  };

  useEffect(() => {
    getAllRegion();
  }, []);

  // Function to handle modal close
  const handleClose = () => setShowModal(false);
  // Function to handle modal show
  const handleShow = () => setShowModal(true);

  return (
    <>
      <section>
        <Heading text={"Region"} />
        <Card>
          <Button
            variant="primary"
            onClick={handleShowAdd}
            size="sm"
            style={{ width: "150px", marginBottom: "20px" }}
          >
            Add Region
          </Button>

          {/* Popup Add-Edit */}
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Region</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                onSubmit={(values, { resetForm }) => {
                  //submitHandler(values, resetForm);
                  if (modalMode === "add") {
                    submitHandler(values, resetForm); // Your existing add functionality
                  } else {
                    // Call edit API
                    editRegion(values, resetForm);
                  }
                }}
                initialValues={{
                  name:
                    modalMode === "edit" && currentEditItem
                      ? currentEditItem.name
                      : "",
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Region Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          {/* Listing Table */}
          <SampleDataTable
            columns={[
              { accessor: "name", Header: "Region" },
              {
                accessor: "id",
                Header: "Action",
                Cell: ({ row }) => (
                  <>
                    <Button
                      className="me-3"
                      onClick={() => handleShowEdit(row.original)}
                    >
                      <FaRegEdit />
                    </Button>
                    <Button
                      variant="danger"
                      className="me-3"
                      onClick={async () => {
                        const isConfirmed = window.confirm(
                          "Are you sure you want to delete this region?"
                        );
                        if (isConfirmed) {
                          // Call delete API

                          setIsLoading(true);
                          try {
                            await RegionAPIs.deleteRegionById(row.original.id);
                            toast.success("Region Deleted Successfully.");
                            getAllRegion(); // Refresh the list
                          } catch (error) {
                            toast.error("Failed to delete region.");
                          }
                          setIsLoading(false);
                        }
                      }}
                    >
                      <FaRegTrashAlt />
                    </Button>
                  </>
                ),
              },
            ]}
            data={region}
          />
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default Region;
