import React, { useState, memo, useEffect } from "react";
import { Form, Button, Table } from "react-bootstrap";

import HourLogAPIs from "../../../../APIs/hour-log";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { UNITS_INITIAL_COLUMNS } from "config/constants";

const AHETableTab = memo(({ logDate, toggleRefresh }) => {
  const [ticketNo, setTicketNo] = useState("");
  const [tickets, setTickets] = useState([]);
  const [userId, setUserId] = useState('');

  const checkTicketStatus = async (e) => {
    const response = await HourLogAPIs.checkTicketStatus(userId, ticketNo);
    if (response.data?.data) {
      return true
    } else {
      alert('Ticket Number does not exist or it has been already added!')
    }
  };

  const handleAddTicket = async () => {
    if (!ticketNo) {
      toast.warn("Please enter a ticket number.");
      return; // Stop the function if the ticket number is empty
    }

    const data = {
      logType: "AHE_TICKET", // Make sure to match the correct logType value expected by your API
      ticketNo: ticketNo,
      startDateTime: new Date(logDate).toISOString(),
      endDateTime: new Date(logDate).toISOString(),
    };

    try {
      if (!(await checkTicketStatus())) return
      const response = await HourLogAPIs.addHourLog(data);
      if (response.data?.success) {
        toast.success("Hour Log Added Successfully");
        // Optionally reset ticketNo state and fetch updated tickets list here
        setTicketNo("");
        // fetchUpdatedTickets(); // Implement this function based on your needs
        fetchQueryHourLogs({
          date: format(new Date(logDate), "yyyy-MM-dd"),
          logType: "AHE_TICKET",
          userId
        });
        toggleRefresh?.()
      } else {
        toast.warn(response?.message);
      }
    } catch (error) {
      console.error("Error adding hour log:", error);
      //toast.error("Error adding hour log");
    }
  };

  const fetchQueryHourLogs = async (data) => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getQueryHourLog(data);
      if (response && response.data && response.data.data.length > 0) {
        // Extract the relevant ticket info from the response
        const extractedTickets = response.data.data.flatMap((log) => log.hourLogDetails
          // log.hourLogDetails.map((detail) => {
          //   const ticketInfo = detail.ticket;
          //   const unitInfo = Object.keys(ticketInfo).reduce((acc, key) => {
          //     // Check if the key contains "Unit" or is "ticketNo", and explicitly exclude "digCommunity" and "community"
          //     if (
          //       (key.toLowerCase().includes("unit") || key === "ticketNo") &&
          //       key !== "digCommunity" &&
          //       key !== "community"
          //     ) {
          //       acc[key] = ticketInfo[key];
          //     }
          //     return acc;
          //   }, {});

          //   return { ...unitInfo, id: detail.id }; // Include any other fields you deem necessary
          // })
        );
        setTickets(extractedTickets);
      } else {
        setTickets([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setTickets([]);
    }
  };

  const handleDelete = async (hourLogDetailId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Ticket?"
    );
    if (!isConfirmed) return;

    const data = {
      hourLogDetailId: hourLogDetailId,
      date: new Date(logDate),
      logType: "AHE_TICKET",
    };

    try {
      await HourLogAPIs.deleteHourLog(data);
      toast.success("Ticket Deleted Successfully");
      await fetchQueryHourLogs({
        date: format(new Date(logDate), "yyyy-MM-dd"),
        logType: "AHE_TICKET",
        userId
      });
      // Optionally, refresh the list of hour logs here if needed
    } catch (error) {
      console.error("Error deleting ticket:", error);
      //toast.error("Failed to delete ticket");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    fetchQueryHourLogs({
      date: format(new Date(logDate), "yyyy-MM-dd"),
      logType: "AHE_TICKET",
      userId: user?.id
    });
    setUserId(user?.id);
  }, []);

  // Function to format column names
  const formatColumnName = (columnName) => {
    return columnName
      .replace(/([A-Z]+)/g, " $1")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (letter) => letter.toUpperCase())
      .trim();
  };

  // Function to format cell values
  const formatValue = (ticket, columnName) => {
    if (columnName.includes('Unit')) {
      return (ticket.ticketPreComplete && ticket.ticketPreComplete[columnName]) ? ticket.ticketPreComplete[columnName] : "0"
    }
    return ticket[columnName] === null || ticket[columnName] === undefined ? "0" : ticket[columnName];
  };

  const columns = tickets.reduce((acc, ticket) => {
    Object.keys(ticket?.ticketPreComplete || {}).forEach((key) => {
      if (key.includes('Unit') && !acc.includes(key))
        acc.push(key);
    });

    return acc.some(item => item.includes('Unit')) ? acc : [...acc, ...UNITS_INITIAL_COLUMNS];
  }, []);

  return (
    <div>
      <Form.Group className="mb-3" controlId="ticketNo">
        <Form.Label>Ticket Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter ticket number"
          value={ticketNo}
          onChange={(e) => setTicketNo(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleAddTicket}>
        Add Ticket
      </Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Ticket Number</th>
            {columns.map((column) => (
              <th key={column}>{formatColumnName(column)}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => {
            return (
              <tr key={ticket.id || index}>
                <td>{ticket.ticketNo}</td>
                {columns.map((column) => (
                  <td key={column}>{formatValue(ticket, column)}</td>
                ))}
                <td className="align-middle text-center">
                  <Button
                    variant="danger"
                    onClick={(e) => handleDelete(ticket.id, e)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
});

export default AHETableTab;
