import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createReason = async (data) => {
  return await API.postMethod(ENDPOINT.reason.create, true, data);
};

const getReason = async () => {
  return await API.getMethod(ENDPOINT.reason.get, true);
};

const getReasonById = async (id) => {
  return await API.getMethod(`${ENDPOINT.reason.get}/${id}`, true);
};

const updateReasonById = async (id, data) => {
  return await API.putMethod(`${ENDPOINT.reason.update}/${id}`, true, data);
};

const deleteReasonById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.reason.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createReason,
  getReasonById,
  getReason,
  updateReasonById,
  deleteReasonById,
};
