import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import SampleDataTable from "Components/DataTables";
import DocTypeAPIs from "../../APIs/doc-type";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";

const DocType = () => {
  const [docType, setDocType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalMode, setModalMode] = useState("add"); // 'add' or 'edit'
  const [currentEditItem, setCurrentEditItem] = useState(null);

  // To open modal in add mode
  const handleShowAdd = () => {
    setModalMode("add");
    setCurrentEditItem(null);
    setShowModal(true);
  };

  // To open modal in edit mode
  const handleShowEdit = (item) => {
    setModalMode("edit");
    setCurrentEditItem(item);
    setShowModal(true);
  };

  const editDocType = async (data, resetForm) => {
    setIsLoading(true);
    try {
      // Assuming your API has an editDocTypeById method
      await DocTypeAPIs.updateDocTypeyById(currentEditItem.id, data);
      toast.success("Doc Type Updated Successfully.");
      setShowModal(false); // Close modal after submission
      getAllDocType(); // Refresh the list
    } catch (error) {
      toast.error("Failed to update Doc Type.");
    }
    setIsLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Doc Type Name. is required"),
  });

  const submitHandler = async (data, resetForm) => {
    setIsLoading(true);
    const res = await DocTypeAPIs.createDocType(data);
    if (res) {
      toast.success("Doc Type Added Successfully.");
      resetForm({ values: "" });
      setShowModal(false); // Close modal after submission
      getAllDocType();
    }
    setIsLoading(false);
  };

  const getAllDocType = async () => {
    const docType = await DocTypeAPIs.getDocType();
    if (docType) {
      setDocType(docType.data.data);
    }
  };

  useEffect(() => {
    getAllDocType();
  }, []);

  // Function to handle modal close
  const handleClose = () => setShowModal(false);
  // Function to handle modal show
  const handleShow = () => setShowModal(true);

  return (
    <>
      <section>
        <Heading text={"Document Type"} />
        <Card>
          <Button
            variant="primary"
            onClick={handleShowAdd}
            size="sm"
            style={{ width: "150px", marginBottom: "20px" }}
          >
            Add Doc Type
          </Button>

          {/* Popup Add-Edit */}
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Doc Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                onSubmit={(values, { resetForm }) => {
                  //submitHandler(values, resetForm);
                  if (modalMode === "add") {
                    submitHandler(values, resetForm); // Your existing add functionality
                  } else {
                    // Call edit API
                    editDocType(values, resetForm);
                  }
                }}
                initialValues={{
                  name:
                    modalMode === "edit" && currentEditItem
                      ? currentEditItem.name
                      : "",
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Doc Type Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          {/* Listing Table */}
          <SampleDataTable
            columns={[
              { accessor: "name", Header: "Doc Type" },
              {
                accessor: "id",
                Header: "Action",
                Cell: ({ row }) => (
                  <>
                    <Button
                      className="me-3"
                      onClick={() => handleShowEdit(row.original)}
                    >
                      <FaRegEdit />
                    </Button>
                    <Button
                      variant="danger"
                      className="me-3"
                      onClick={async () => {
                        const isConfirmed = window.confirm(
                          "Are you sure you want to delete this Doc Type?"
                        );
                        if (isConfirmed) {
                          // Call delete API

                          setIsLoading(true);
                          try {
                            await DocTypeAPIs.deleteDocTypeById(
                              row.original.id
                            );
                            toast.success("Doc Type Deleted Successfully.");
                            getAllDocType(); // Refresh the list
                          } catch (error) {
                            toast.error("Failed to delete Doc Type.");
                          }
                          setIsLoading(false);
                        }
                      }}
                    >
                      <FaRegTrashAlt />
                    </Button>
                  </>
                ),
              },
            ]}
            data={docType}
          />
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default DocType;
