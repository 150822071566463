import React, { useEffect } from "react";

import ClientRoutes from "Routes";
import "Style/global.scss";
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for react-datepicker


const App = () => {
  useEffect(() => { // fix for resize observer issue
    window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop limit exceeded') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
}, []);
  return <ClientRoutes />;
};

export default App;
