import React, { useState, useCallback, memo, useEffect } from "react";
import { Button, Form, Table, Row, Col } from "react-bootstrap";

import HourLogAPIs from "../../../../APIs/hour-log";
import { toast } from "react-toastify";
import { format } from "date-fns";

const PayrollReimbursementTab = memo(({ logDate, selectedUserId }) => {
  const [reimbursements, setReimbursements] = useState([]);

  const fetchReimbursements = async () => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getReimbursements(format(new Date(logDate), "yyyy-MM-dd"), selectedUserId);
      if (response && response.data && response.data.data.length > 0) {
        setReimbursements(response.data.data);
      } else {
        setReimbursements([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setReimbursements([]);
    }
  };

  useEffect(() => {
    fetchReimbursements();
  }, [logDate]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Price</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {reimbursements.map((item, index) => (
          <tr key={index}>
            <td>
              {item?.files[0]?.presignedUrl ? (
                <a
                  href={item.files[0].presignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.files[0].description}
                </a>
              ) : (
                "No Link"
              )}
            </td>
            <td>{item.amount}</td>
            <td>{item.expenseType}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});

export default PayrollReimbursementTab;
