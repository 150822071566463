import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import profile from "../../Images/profile.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "redux/reducers/auth";

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser ] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    setIsLoggedIn(true);
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsOpen(!isOpen);
    dispatch(
      logout()
    )
    localStorage.clear();
    navigate('/');

  };

  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem('user'))
    setUser(user);
  },[])

  return (
    <Dropdown show={isOpen} onToggle={() => {}}>
      <Dropdown.Toggle variant="light" id="profile-dropdown" onClick={() => setIsOpen(!isOpen)}>
       <img src={profile} alt="img" className="me-3" />
        <span className="ml-2">{user?.firstName}</span>
        <MdKeyboardArrowDown size={"22px"} className="ml-2" style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.2s" }}>
        </MdKeyboardArrowDown>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {isLoggedIn ? (
          <Dropdown.Item onClick={handleLogout}><FiLogOut/>Logout</Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={handleLogin}>Login</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
