import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TailSpin } from 'react-loader-spinner'

import classes from "./style.module.scss";

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const Loader = ({ isLoading }) => (
  <div
    className={classNames({
      [classes.loaderComponent]: true,
    })}
  >
    <TailSpin
      height="80"
      width="80"
      color="#0073ba"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={isLoading}
    />
  </div>
);

Loader.propTypes = propTypes;

export default Loader;
