import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createTicketType = async (data) => {
  return await API.postMethod(ENDPOINT.tickettype.create, true, data);
};

const getTicketType = async () => {
  return await API.getMethod(ENDPOINT.tickettype.get, true);
};

const getTicketTypeById = async (id) => {
  return await API.getMethod(`${ENDPOINT.tickettype.get}/${id}`, true);
};

const updateTicketTypeById = async (id, data) => {
  return await API.putMethod(`${ENDPOINT.tickettype.update}/${id}`, true, data);
};

const deleteTicketTypeById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.tickettype.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createTicketType,
  getTicketType,
  getTicketTypeById,
  updateTicketTypeById,
  deleteTicketTypeById,
};
