import React, { useRef, useState } from 'react'
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import { useEffect } from 'react';

const CustomDatePicker = (props) => {
  const datePickerRef = useRef()
  const [years, setYears] = useState([])
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let arr = []
    const presentYear = props.maxDate ? new Date(props.maxDate).getFullYear() : '2090'
    let year = 1940
    while (year <= presentYear) {
      arr.push(year)
      year++
    }
    setYears(arr)
  }, [props.maxDate])

  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
          <div>
            <p style={{cursor:'pointer'}} className='p-1 text-center' onClick={() => {props.onSelect(null);datePickerRef.current?.clear()}}>Clear</p>
          </div>
        </CalendarContainer>
      </div>
    );
  }

  return (
    <ReactDatePicker
      {...props}
      ref={datePickerRef}
      calendarContainer={MyContainer}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
    />
  )
}

export default CustomDatePicker