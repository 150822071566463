import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { toast } from "react-toastify";

import TicketAPIs from "../../../APIs/ticket";
import ReasonAPIs from "../../../APIs/reason";
import AuthAPIs from "../../../APIs/auth";
import FileAPIs from "../../../APIs/file";
import { formatDate } from "Helper/Converters";
import { useSelector } from "react-redux";
import axios from "axios";
import { ENDPOINT } from "config/constants";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";

const RescheduleModal = React.memo(
  ({ ticketIds, showModal, setShowModal, date }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [rescheduleDate, setRescheduleDate] = useState(date || new Date());
    const [operationReasons, setOperationReasons] = useState([]);
    const [operationReason, setOperationReason] = useState({});
    const [operationComment, setOperationComment] = useState("");
    const [operationSpeakTo, setOperationSpeakTo] = useState("");
    const [operationEmail, setOperationEmail] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedUserName, setSelectedUserName] = useState("");
    const [loader, setLoader] = useState(false);
    const user = useSelector((state) => state.auth);
    const userId = user.userId;

    useEffect(() => {
      date && setRescheduleDate(date);
    }, [date]);

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    // const handleUpload = async (event) => {
    //   event.preventDefault(); // Prevent default form submission behavior
    //   if (!selectedFile) {
    //     alert("Please select a file");
    //     return;
    //   }
    //   setUploadFileLoader(true);
    //   const formData = new FormData();
    //   formData.append("files", selectedFile);
    //   var bearer_token = localStorage.getItem("accessToken");
    //   //headers["Authorization"] = `Bearer ${bearer_token}
    //   try {
    //     // Replace 'YOUR_UPLOAD_API_ENDPOINT' with the actual API endpoint for file upload
    //     const response = await axios.post(
    //       `${ENDPOINT.tickets.uploadFile}/${ticketData?.id}/upload-files`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           Authorization: `Bearer ${bearer_token}`,
    //         },
    //       }
    //     );

    //     toast.success("File uploaded successfully");
    //     setSelectedFile(null);
    //     getTicketFiles();
    //   } catch (error) {
    //     toast.error(`File not uploaded: ${error.message}`);
    //   } finally {
    //     setUploadFileLoader(false);
    //   }
    // };

    const FileUploadComponent = ({ selectedFile }) => {
      return (
        <div className="d-flex align-items-center w-100">
          <label htmlFor="fileInput" className="btn btn-primary">
            Choose a file
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {selectedFile && (
            <span style={{ marginLeft: 10 }}>
              {selectedFile.type.startsWith("image") ? (
                <img
                  style={{ height: 250, width: 250 }}
                  src={URL.createObjectURL(selectedFile)}
                />
              ) : (
                selectedFile?.name
              )}
            </span>
          )}
          {/* <Button
          style={{ minWidth: "140px" }}
          onClick={handleUpload}
          disabled={uploadFileLoader}
        >
          {uploadFileLoader ? "Loading..." : "Upload"}
        </Button> */}
        </div>
      );
    };

    const customStyles = {
      control: (provided) => ({
        ...provided,
        minHeight: "44px",
      }),
    };

    const getUsers = async () => {
      const users = await AuthAPIs.getAllUsers();
      const customArr = [];
      if (users) {
        users.data?.data?.forEach((item) => {
          const data = {
            value: item?.id,
            label: `${item?.firstName} ${item?.lastName}`,
          };
          if (item?.id !== userId) customArr.push(data);
        });
        customArr.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setUsers([{ value: "", label: "Select" }, ...customArr]);
      }
    };

    const getAllReason = async () => {
      const types = await ReasonAPIs.getReason();

      const customArr = [];
      if (types) {
        const arr = [...types.data.data];
        arr?.forEach((item) => {
          const data = {
            value: item?.id,
            label: `${item?.name}`,
          };
          customArr.push(data);
        });
        setOperationReasons(customArr);
        setOperationReason(customArr[1] || {});
      }
    };

    const handleOperationLog = async () => {
      if (!selectedUser) {
        toast.error("Please Select User ");
        return;
      }
      if (!operationReason?.label && !operationComment) {
        toast.error("Add Reason and Comment");
        return;
      }
      if (
        operationEmail &&
        !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(operationEmail)
      ) {
        toast.error("Invalid Email");
        return;
      }

      const remarks =
        operationReason?.label === "Re-Scheduled"
          ? `Ticket rescheduled by ${selectedUserName} for ${formatDate(
              rescheduleDate
            )} after speaking with ${operationSpeakTo} - ${operationComment}`
          : `${operationReason?.label} - ${operationComment}`;
      setLoader(true);

      const res = await Promise.all([
        TicketAPIs.rescheduleTicket({
          ticketId: ticketIds,
          rescheduleDate: moment(date).format("YYYY-MM-DD"),
        }),
        TicketAPIs.addTicketLog({
          ticketIds: ticketIds,
          userId: selectedUser,
          action: "OPERATION_LOG",
          remarks: remarks,
        }),
        operationEmail
          ? TicketAPIs.sendEmail({
              ticketIds,
              userId,
              to: operationEmail,
              subject: "Operation Log",
              body: remarks,
              attachments: [],
            })
          : "email",
      ]);
      if (operationReason?.label === "Re-Scheduled") {
        const formData = new FormData();
        if (selectedFile) formData.append("files", selectedFile);
        await TicketAPIs.rescheduleTicket({
          ticketId: ticketIds,
          rescheduleDate: moment(rescheduleDate).format("YYYY-MM-DD"),
        });
        for (const ticketId of ticketIds) {
          if (selectedFile) await FileAPIs.uploadFile(ticketId, formData);
        }
      }
      if (res.every((item) => item)) {
        setShowModal(false);
        toast.success(
          "Ticket rescheduled successfully and operation log added"
        );
        setSelectedUser(null);
        setOperationReason({});
        setOperationSpeakTo("");
        setOperationEmail("");
        setOperationComment("");
        setSelectedFile(null);
      }
      setLoader(false);
    };

    useEffect(() => {
      getUsers();
      getAllReason();
    }, []);

    return (
      <>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Reschedule Ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="form-group">
                <Form.Label>Specific User</Form.Label>
                <Select
                  options={users}
                  styles={customStyles}
                  onChange={(selectedOption) => {
                    setSelectedUserName(selectedOption.label);
                    setSelectedUser(selectedOption.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Specific Reason</Form.Label>
                <Select
                  options={operationReasons}
                  styles={customStyles}
                  value={operationReason}
                  onChange={(selectedOptions) => {
                    setOperationReason(selectedOptions);
                    setOperationEmail("");
                  }}
                />
              </Form.Group>

              {operationReason?.label === "Re-Scheduled" && (
                <Row>
                  <Col className="column" md={3}>
                    <Form.Group className="form-group mb-0">
                      <Form.Label>Reschedule Date</Form.Label>
                      <div className="datepicker_box">
                        <CustomDatePicker
                          selected={rescheduleDate}
                          onSelect={(date) => setRescheduleDate(date)}
                          dateFormat="MM/dd/yyyy"
                        />
                        <span>
                          <MdDateRange />
                        </span>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className="column" md={3}>
                    <Form.Group className="form-group">
                      <Form.Label>Who did you speak with ?</Form.Label>
                      <Form.Control
                        type="text"
                        value={operationSpeakTo}
                        onChange={(evt) => {
                          setOperationSpeakTo(evt.currentTarget.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="column" md={3}>
                    <Form.Group className="form-group">
                      <Form.Label> Email a re-schedule notice to</Form.Label>
                      <Form.Control
                        type="text"
                        value={operationEmail}
                        onChange={(evt) => {
                          setOperationEmail(evt.currentTarget.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Form.Group className="form-group">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="number"
                  as={"textarea"}
                  rows={3}
                  value={operationComment}
                  onChange={(evt) => {
                    setOperationComment(evt.currentTarget.value);
                  }}
                />
              </Form.Group>
              <Row className="d-flex justify-content-between mb-4">
                {operationReason?.label === "Re-Scheduled" && (
                  <Col>
                    <FileUploadComponent selectedFile={selectedFile} />
                  </Col>
                )}
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Col className="d-flex justify-content-end">
              <Button onClick={handleOperationLog}>
                {loader ? "Loading..." : "Save"}
              </Button>
              <Button
                onClick={() => setShowModal(false)}
                style={{ marginLeft: 10 }}
              >
                Close
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);

export default RescheduleModal;
