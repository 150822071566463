import React from "react";
import { Modal, Button } from "react-bootstrap";

const CallLogModal = ({ logData, showModal, setShowModal }) => {
  let parsedData;
  try {
    parsedData = JSON.parse(logData);
  } catch (error) {
    console.error("Error parsing logData JSON:", error);
    parsedData = "Invalid JSON data";
  }

  const JsonObjectDisplay = ({ data, indentLevel = 0 }) => {
    if (typeof data !== "object" || data === null) {
      // Data is not an object or is null, render it directly
      return <span>{JSON.stringify(data)}</span>;
    }

    const indentStyle = {
      marginLeft: `${indentLevel * 20}px`,
    };

    return (
      <div>
        {Object.entries(data).map(([key, value]) => (
          <div key={key} style={indentStyle}>
            <strong>{key}:</strong>{" "}
            {typeof value === "object" ? (
              <JsonObjectDisplay data={value} indentLevel={indentLevel + 1} />
            ) : (
              JSON.stringify(value)
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Call Log Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof parsedData === "string" ? (
          <div>{parsedData}</div>
        ) : (
          <JsonObjectDisplay data={parsedData} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowModal(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallLogModal;
