import React, { memo, useEffect, useState } from "react";
import { Button, Table, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik, FieldArray } from "formik";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  BiEdit,
  BiLike,
  BiSave,
  BiSolidHandDown,
  BiSolidHandRight,
  BiSolidHandUp,
  BiTrash,
} from "react-icons/bi";

import HourLogAPIs from "../../../../APIs/hour-log";
import { toast } from "react-toastify";
import { formatDate, formatTime } from "Helper/Converters";
import { format, set } from "date-fns";
import moment from "moment";

let endTime = new Date();
endTime.setHours(23, 59, 0, 0);

const LogTableTab = memo(({ logDate, toggleRefresh }) => {
  const [showTicketInput, setShowTicketInput] = useState(false);

  const dropdownOptions = [
    { label: "Work", value: "Work" },
    { label: "Lunch", value: "Lunch" },
    { label: "In Meeting", value: "In Meeting" },
    { label: "Assisting Project", value: "Assisting Project" },
    { label: "Training", value: "Training" },
    { label: "Paid Time Off", value: "Paid Time Off" },
    { label: "Unpaid Time Off", value: "Unpaid Time Off" },
  ];

  const dropdownWorkCodeOptions = [
    { label: "WorkHours", value: "WorkHours" },
    { label: "StatHoliday", value: "StatHoliday" },
    { label: "Vacation", value: "Vacation" },
  ];

  const [hourLogs, setHourLogs] = useState([]);

  // State to track editing status of each log
  const [editingLogs, setEditingLogs] = useState({});
  const [editState, setEditState] = useState(null);

  const [user, setUser] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to toggle edit mode
  const toggleEdit = (logId) => {
    setEditingLogs((prev) => ({
      ...prev,
      [logId]: !prev[logId],
    }));
  };

  const handleCancelEdit = () => {
    setEditState(null);
  };

  // Improved handleEdit function
  const handleEdit = (log) => {
    setEditState({
      id: log.id,
      values: { ...log },
    });
  };

  // Adjusted handleSaveEdit function
  const handleSaveEdit = async () => {
    const { index, values } = editState;
    try {
      // Prepare your payload here based on `values`
      const payload = {
        hourLogDetailId: values.id,
        startDateTime: moment(values.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDateTime: moment(values.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        ticketNo: values.ticketNo,
        note: values.note,
        workLog: values.workLog,
      };

      // Assuming update API call
      await HourLogAPIs.updateHourLog(payload);
      toast.success("Log updated successfully");

      // Reset edit state and refresh logs
      setEditState(null);
      fetchQueryHourLogs({
        date: format(new Date(logDate), "yyyy-MM-dd"),
        logType: "HOUR_LOG",
        userId: user?.id,
      });
      toggleRefresh?.();
    } catch (error) {
      console.error("Failed to update log", error);
      //toast.error("Failed to update log");
    }
  };

  // Handle update
  const handleUpdate = async (logRow, index) => {
    // Construct the payload from the updated form fields
    const payload = {
      ...logRow,
      // Include any updated fields here
    };
    try {
      // Assuming you have an API function to update the log
      await HourLogAPIs.updateHourLog(payload);
      toast.success("Log updated successfully");
      toggleEdit(logRow.id); // Exit edit mode
      // Refresh data
      fetchQueryHourLogs({
        date: format(new Date(logDate), "yyyy-MM-dd"),
        logType: "HOUR_LOG",
        userId: user?.id,
      });
      toggleRefresh?.();
    } catch (error) {
      console.error("Failed to update log", error);
      //toast.error("Failed to update log");
    }
  };

  // Initial state for logRows with an example empty row or predefined rows
  const initialValues = {
    logRows: [
      {
        startDateTime: new Date(logDate),
        endDateTime: new Date(new Date(logDate).getTime() + 300000),
        ticketNo: "",
        note: "",
        workLog: dropdownOptions[0]?.value,
        workCode: dropdownWorkCodeOptions[0]?.value,
        logType: "HOUR_LOG",
        title: `Log of ${new Date(logDate)}`,
      },
    ],
  };

  const submitLogRow = async (logRow) => {
    if (user?.department?.name !== "admin" && logRow.workLog === "Work") {
      if (!logRow.ticketNo) {
        toast.warn("Please enter a ticket number.");
        return; // Stop the function if the ticket number is empty
      }
      const ticketState = await checkTicketStatus(logRow.ticketNo);
      if (!ticketState) {
        toast.error(
          "Ticket Number does not exist or it has been already added!"
        );
        return;
      }
    }

    try {
      logRow.logType = "HOUR_LOG";
      const resp = await HourLogAPIs.addHourLog(logRow);
      return resp; // Return true to indicate success
    } catch (error) {
      console.error("Error adding hour log:", error);
      return false; // Return false to indicate failure
    }
  };

  const checkTicketStatus = async (ticketNumber) => {
    try {
      // setTicketStatus(false)
      const response = await HourLogAPIs.checkTicketStatus(
        user?.id,
        ticketNumber
      );
      if (response.data?.data) {
        // setTicketStatus(true)
        return true;
      } else {
        // setTicketStatus(false)
        return false;
      }
    } catch (error) {
      // setTicketStatus(false)
    }
  };

  const fetchQueryHourLogs = async (data) => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getQueryHourLog(data);
      if (response && response.data && response.data.data.length > 0) {
        setHourLogs(response.data.data[0].hourLogDetails);
      } else {
        setHourLogs([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setHourLogs([]);
    }
  };

  const handleDelete = async (hourLogDetailId, event) => {
    event.preventDefault();
    event.stopPropagation();

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this log?"
    );
    if (!isConfirmed) return;

    const data = {
      hourLogDetailId: hourLogDetailId,
      date: new Date(logDate),
      logType: "HOUR_LOG",
    };

    try {
      await HourLogAPIs.deleteHourLog(data);
      toast.success("Hour Log Deleted Successfully");
      await fetchQueryHourLogs({
        date: format(new Date(logDate), "yyyy-MM-dd"),
        logType: "HOUR_LOG",
        userId: user?.id,
      });
      toggleRefresh?.();
      // Optionally, refresh the list of hour logs here if needed
    } catch (error) {
      console.error("Error deleting hour log:", error);
      //toast.error("Failed to delete hour log");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    fetchQueryHourLogs({
      date: format(new Date(logDate), "yyyy-MM-dd"),
      logType: "HOUR_LOG",
      userId: user?.id,
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          let allSuccessful = true;

          // check in logRows array there should not be same ticket number
          values.logRows.forEach((logRow, index) => {
            if (
              logRow.ticketNo !== "" &&
              values.logRows.findIndex(
                (row) => row.ticketNo === logRow.ticketNo
              ) !== index
            ) {
              toast.error("Ticket Number should be unique");
              allSuccessful = false;
              setLoading(false);
              return;
            }
          });

          for (const logRow of values.logRows) {
            const isSuccess = await submitLogRow({
              ...logRow,
              title: `${formatTime(logRow.startDateTime)} ${formatTime(
                logRow.endDateTime
              )} on ${formatDate(logRow.startDateTime)}`,
              startDateTime: moment(logRow.startDateTime).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
              endDateTime: moment(logRow.endDateTime).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
            });
            if (!isSuccess) {
              allSuccessful = false;
              setLoading(false);
              //toast.error("Failed to add hour log");
              break; // Exit loop on first failure
            }
          }

          // Show success toast only if all submissions were successful
          if (allSuccessful) {
            toast.success("All Hour Logs Added Successfully");
            resetForm(); // Optionally reset the form
            await fetchQueryHourLogs({
              date: format(new Date(logDate), "yyyy-MM-dd"),
              logType: "HOUR_LOG",
              userId: user?.id,
            });
            toggleRefresh?.();
            setLoading(false);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray name="logRows">
              {({ push, remove }) => (
                <>
                  <Row>
                    <Col>
                      <Button
                        style={{ marginBottom: 20 }}
                        onClick={() => {
                          push({
                            startDateTime: new Date(logDate),
                            endDateTime: new Date(
                              new Date(logDate).getTime() + 300000
                            ),
                            ticketNo: "",
                            note: "",
                            workLog: dropdownOptions[0]?.value,
                            workCode: dropdownWorkCodeOptions[0]?.value,
                            title: `Log of ${new Date(logDate)}`,
                          });
                        }}
                      >
                        Add New Activity
                      </Button>
                    </Col>
                    <Col>
                      <Select
                        options={dropdownWorkCodeOptions}
                        onChange={(option) =>
                          setFieldValue(`logRows[0].workCode`, option.value)
                        }
                        value={dropdownWorkCodeOptions.find(
                          (option) =>
                            option.value === values?.logRows[0]?.workCode
                        )}
                      />
                    </Col>
                  </Row>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        {user?.role != "admin" &&
                          user?.department?.name != "admin" && (
                            <th>Ticket Number</th>
                          )}
                        <th>Note</th>
                        <th>Work Log</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="logRows">
                        {({ remove, push }) =>
                          values.logRows.map((row, index) => (
                            <tr key={index}>
                              <td className="align-middle text-center">
                                <DatePicker
                                  className="form-control"
                                  selected={values.logRows[index].startDateTime}
                                  onChange={(date) => {
                                    if (moment(date).isValid()) {
                                      setFieldValue(
                                        `logRows[${index}].startDateTime`,
                                        date
                                      );
                                      setFieldValue(
                                        `logRows[${index}].endDateTime`,
                                        new Date(
                                          new Date(date).getTime() + 300000
                                        )
                                      );
                                    }
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Start"
                                  dateFormat="h:mm aa"
                                />
                              </td>
                              <td className="align-middle text-center">
                                <DatePicker
                                  className="form-control"
                                  selected={values.logRows[index].endDateTime}
                                  onChange={(date) => {
                                    if (
                                      moment(date).isValid() &&
                                      date.getTime() >
                                        values.logRows[
                                          index
                                        ].startDateTime.getTime()
                                    ) {
                                      setFieldValue(
                                        `logRows[${index}].endDateTime`,
                                        date
                                      );
                                    }
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="End"
                                  dateFormat="h:mm aa"
                                  minTime={
                                    new Date(
                                      values.logRows[
                                        index
                                      ].startDateTime.getTime() + 300000
                                    )
                                  }
                                  maxTime={endTime}
                                />
                              </td>
                              {user?.role != "admin" &&
                                user?.department?.name != "admin" && (
                                  <>
                                    <td className="align-middle text-center">
                                      {row.workLog === "Work" && (
                                        <Form.Control
                                          type="text"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `logRows[${index}].ticketNo`,
                                              e.target.value
                                            )
                                          }
                                          value={values.logRows[index].ticketNo}
                                          // onBlur={checkTicketStatus}
                                        />
                                      )}
                                    </td>
                                  </>
                                )}
                              <td className="align-middle text-center">
                                <Form.Control
                                  as="textarea"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `logRows[${index}].note`,
                                      e.target.value
                                    )
                                  }
                                  value={values.logRows[index].note}
                                />
                              </td>
                              <td className="align-middle text-center">
                                <Select
                                  options={dropdownOptions}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `logRows[${index}].workLog`,
                                      option.value
                                    );
                                    if (option.value == "Work") {
                                      setShowTicketInput(true);
                                    } else {
                                      setShowTicketInput(false);
                                    }
                                  }}
                                  value={dropdownOptions.find(
                                    (option) =>
                                      option.value ===
                                      values.logRows[index].workLog
                                  )}
                                />
                              </td>
                              <td className="align-middle text-center">
                                <Button
                                  variant="danger"
                                  onClick={() => remove(index)}
                                >
                                  <BiTrash />
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                      </FieldArray>
                    </tbody>
                  </Table>
                  {!loading && (
                    <Button type="submit" variant="success">
                      Save
                    </Button>
                  )}
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
      <Row>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Ticket Number</th>
              <th>Note</th>
              <th>Work Log</th>
              <th>Approved</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {hourLogs.map((log, index) => {
              const isEditing = editState?.id === log.id;
              return (
                <tr key={log.id || index}>
                  <td>
                    {isEditing ? (
                      <DatePicker
                        selected={new Date(editState.values.startDate)}
                        onChange={(date) => {
                          setEditState((prev) => ({
                            ...prev,
                            values: { ...prev.values, startDate: date },
                          }));
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    ) : (
                      log.startDate
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <DatePicker
                        selected={new Date(editState.values.endDate)}
                        onChange={(date) =>
                          setEditState((prev) => ({
                            ...prev,
                            values: { ...prev.values, endDate: date },
                          }))
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minTime={
                          new Date(
                            new Date(editState.values.startDate).getTime() +
                              300000
                          )
                        }
                        maxTime={endTime}
                      />
                    ) : (
                      log.endDate
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Form.Control
                        type="text"
                        defaultValue={editState.values.ticketNo}
                        onChange={(e) =>
                          setEditState((prev) => ({
                            ...prev,
                            values: {
                              ...prev.values,
                              ticketNo: e.target.value,
                            },
                          }))
                        }
                      />
                    ) : (
                      log.ticketNo
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Form.Control
                        as="textarea"
                        defaultValue={editState.values.note}
                        onChange={(e) =>
                          setEditState((prev) => ({
                            ...prev,
                            values: { ...prev.values, note: e.target.value },
                          }))
                        }
                      />
                    ) : (
                      log.note
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <Select
                        options={dropdownOptions}
                        defaultValue={dropdownOptions.find(
                          (option) => option.value === editState.values.workLog
                        )}
                        onChange={(option) =>
                          setEditState((prev) => ({
                            ...prev,
                            values: { ...prev.values, workLog: option.value },
                          }))
                        }
                      />
                    ) : (
                      log.workLog
                    )}
                  </td>
                  <td>
                    {log.isApproved ? (
                      <BiLike color="green" size={30} />
                    ) : (
                      <BiLike color="red" size={30} />
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <>
                        <Button
                          variant="success"
                          className="me-2"
                          onClick={() =>
                            handleSaveEdit(index, editState.values)
                          }
                        >
                          Save
                        </Button>
                        <Button variant="secondary" onClick={handleCancelEdit}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="primary"
                          className="me-2"
                          onClick={() => handleEdit(log)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          onClick={(e) => handleDelete(log.id, e)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default LogTableTab;
