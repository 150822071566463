import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { TbClipboardCheck } from "react-icons/tb";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable"; // Replace with your custom table component

const AlertMesaage = () => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const options = [
    { value: "0", label: "Open this select menu" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const dummyData = [
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
    {
      id: "1",
      alert: "Emergency Ticket",
      number: "20232112219",
      date: "May-24-2023",
      button: <Button>Disable</Button>,
    },
  ];

  const columns = [
    { id: "alert", label: "Alert Messages" },
    { id: "number", label: "Ticket Number" },
    { id: "date", label: "Alert On Data" },
    { id: "button", label: "" },
  ];

  return (
    <>
      <section>
        <Heading text={"Alert Messages"} />

        <Card>
          {/* <DataTable columns={columns} data={dummyData} style /> */}
        </Card>
      </section>
    </>
  );
};

export default AlertMesaage;
