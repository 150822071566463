import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const login = async (email, password) => {
  return await API.postMethod(ENDPOINT.auth.login, false, {
    email,
    password,
  });
};

const getUsers = async () => {
  return await API.getMethod(ENDPOINT.users.getAll+'?sort=updatedAt,DESC', true);
};

const getUserById = async (id) => {
  return await API.getMethod(`${ENDPOINT.users.getUserByID}/${id}`, true);
};

const getAllUsers = async () => {
  return await API.getMethod(`${ENDPOINT.users.getAllIds}`, true);
};

const getDepartments = async () => {
  return await API.getMethod(ENDPOINT.auth.department, true);
};

const registerUser = async (data) => {
  return await API.postMethod(ENDPOINT.auth.registerUser, false, data);
};

const updateUser = async (id, data) => {
  return await API.postMethod(`${ENDPOINT.users.updateUser}/${id}`, true, data);
};

const deleteUser = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.users.getAll}/${id}`, true);
};

const permissions = async (data) => {
  return await API.postMethod(ENDPOINT.auth.permissions, true, data);
};

const getOneUser = async (id) => {
  return await API.getMethod(`${ENDPOINT.users.getAll}/${id}`, true);
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  getUsers,
  getDepartments,
  registerUser,
  getUserById,
  updateUser,
  deleteUser,
  permissions,
  getOneUser,
  getAllUsers,
};
