import React, { useState, useCallback, memo, useEffect } from "react";
import { Modal, Button, Tab, Tabs, Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReimbursementTab from "./tabs/ReimbursementTab";
import LogTableTab from "./tabs/LogTableTab";
import PerTableTab from "./tabs/PerTableTab";
import AHETableTab from "./tabs/AHETableTab";
import { formatDate } from "Helper/Converters";
import { format } from "date-fns";
import TicketSummaryTab from "./tabs/TicketSummaryTab";
import { useSelector } from "react-redux";

const AddLogModal = memo(({ show, handleClose, logDate, setLogDate }) => {
  const [key, setKey] = useState("logTable");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { department } = useSelector((state) => state.auth);

  //dummy
  const dropdownOptions = [
    { label: "Work", value: "Work" },
    { label: "Lunch", value: "Lunch" },
  ];

  // Restrict future dates
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    // Ensure the DatePicker is hidden when the modal is not shown
    if (!show) {
      setDatePickerVisible(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setDatePickerVisible(false); // Ensure DatePicker is hidden when modal is closed
      }}
      size="lg"
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Selected Log Date
          <span
            onClick={() => {
              setDatePickerVisible(!isDatePickerVisible);
              setLogDate(new Date(logDate));
            }}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            ({new Date(formatDate(logDate)).toDateString()})
          </span>
          {isDatePickerVisible && (
            <div style={{ position: "absolute", zIndex: 1050 }}>
              <DatePicker
                selected={new Date(formatDate(logDate))}
                onSelect={(date) => {
                  setLogDate(new Date(date));
                  setDatePickerVisible(false);
                }}
                maxDate={new Date()}
                inline
              />
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {department?.name == "admin" ? (
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="logTable" title="Hour Log">
              <LogTableTab
                dropdownOptions={dropdownOptions}
                logDate={logDate}
                key={logDate}
                toggleRefresh={() => setRefresh((prev) => !prev)}
              />
            </Tab>
          </Tabs>
        ) : (
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="logTable" title="Hour Log">
              <LogTableTab
                dropdownOptions={dropdownOptions}
                logDate={logDate}
                key={logDate}
                toggleRefresh={() => setRefresh((prev) => !prev)}
              />
            </Tab>
            <Tab eventKey="ticketSummary" title="Ticket Summary">
              <TicketSummaryTab
                logDate={logDate}
                key={logDate}
                refresh={refresh}
              />
            </Tab>
            <Tab eventKey="perTicket" title="Per Ticket">
              <PerTableTab
                logDate={logDate}
                key={logDate}
                toggleRefresh={() => setRefresh((prev) => !prev)}
              />
            </Tab>
            <Tab eventKey="ahe" title="AHE Ticket">
              <AHETableTab
                logDate={logDate}
                key={logDate}
                toggleRefresh={() => setRefresh((prev) => !prev)}
              />
            </Tab>
            <Tab eventKey="reimbursement" title="Reimbursement">
              <ReimbursementTab logDate={logDate} key={logDate} />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default AddLogModal;
