import React from "react";
import { BiCopyright } from "react-icons/bi";

const Footer = () => {
  return (
    <footer>
      <p><BiCopyright/> 2023 - Ticketview System</p>
    </footer>
  );
};

export default Footer;
