import React from "react";
import classes from "./index.module.scss";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.png";
import { useSelector } from "react-redux";

const Logo = () => {
    const { department } = useSelector((state) => state.auth);
    return (
        <>
            <div className={classes.logo}>
                <Link to={(department?.name == 'Tester' || department?.name == 'admin' || department?.name == "manager") ? '/admin-dashboard' : '/dashboard/query-search'}>
                    <img src={logo} alt={"OpenPitch"} />
                </Link>
            </div>
        </>
    )
}

export default Logo;