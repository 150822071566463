import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createStationCode = async (data) => {
  return await API.postMethod(ENDPOINT.stationcode.create, true, data);
};

const getStationCode = async () => {
  return await API.getMethod(ENDPOINT.stationcode.get, true);
};

const getStationCodeById = async (id) => {
  return await API.getMethod(`${ENDPOINT.stationcode.get}/${id}`, true);
};

const updateStationCodeById = async (id, data) => {
  return await API.putMethod(
    `${ENDPOINT.stationcode.update}/${id}`,
    true,
    data
  );
};

const deleteStationCodeById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.stationcode.delete}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createStationCode,
  getStationCode,
  getStationCodeById,
  updateStationCodeById,
  deleteStationCodeById,
};
