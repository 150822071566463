import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { TbClipboardCheck } from "react-icons/tb";
import Footer from "Components/Footer";
import AuthAPIs from "../../APIs/auth";
import Loader from "Components/Loader";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import { MdClose } from "react-icons/md";
import { wrapLastTextInBracketsWithStrong } from "Utils/utils";
import { formatDate } from "date-fns";

const AssignTIckets = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [users, setUsers] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [stats, setStats] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [loadingComponent, setLoadingComponent] = useState(true);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  const handleSelectionChange = (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [];
    if (users) {
      users.data?.data?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        if (item?.id !== userId) customArr.push(data);
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
    setIsLoading(false);
  };

  // const getTickets = async () => {
  //   const tickets = await TicketAPIs.search();
  //   const customArr = [{ value: "", label: "Select" }];
  //   if (tickets) {
  //     const arr = [
  //       ...tickets.data.data.filter((item) => item.assignTo === null),
  //     ];
  //     arr?.forEach((item) => {
  //       const data = { value: item?.id, label: item?.ticketNo };
  //       customArr.push(data);
  //     });
  //     setUnAssignedTicket(customArr);
  //   }
  //   setIsLoading(false);
  // };

  const getTicketStats = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.ticketStats();
    if (res) {
      setStats(res.data.data);
    }
  };

  useEffect(() => {
    getTicketStats();
    getUsers();
    //getTickets();
  }, []);

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {row.original.digAddress} {"\n"}
            {row.original.digCity}
            {row.original.company_name} {"\n"}
            {row.original.contractor_name} {"\n"}
            {row.original.region} {"\n"}
            <strong>{`(${row.original.priority})`}</strong>
          </div>
        );
      },
    },
    { accessor: "entered", Header: "Entered" },
    {
      accessor: "assigntoo",
      Header: "Assigned To",
      Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    {
      accessor: "due",
      Header: "Due",
      Cell: ({ row }) => (
        <p>
          {row.original.rescheduleDate
            ? row.original.rescheduleDate
            : row.original.due}
        </p>
      ),
    },
    { accessor: "completedDate", Header: "Completed" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
  ];

  /**
   * Assign Ticket Submit Handler.
   */
  const assignTicket = async () => {
    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (selectedIds.length === 0) {
      toast.error("No Tickets Selected.");
      return;
    }
    setIsLoading(true);
    setLoadingComponent(false);
    const res = await TicketAPIs.assignReassignTicket({
      ticketId: selectedIds,
      userId: selectedUser,
      type: "assign",
      isAssignPerTicket: checkedItems["isAssignPerTicket"],
    });
    if (res.data.success) {
      // toast.success("Ticket Assigned Successfully");
      const selectedTicketsNumbers = searchedData
        .filter((item) => selectedIds.indexOf(item.id) > -1)
        .map((item) => item.ticketNo);
      setSuccessMsg(
        `Following Tickets ${selectedTicketsNumbers.join(
          ","
        )} has been assigned successfully.`
      );
      // we need to remove the assigned ticket from the list
      const updatedTickets = searchedData.filter(
        (item) => selectedIds.indexOf(item.id) === -1
      );
      setSelectedIds([]);
      setSelectedTicket(null);
      setSelectedUser(null);
      setSearchedData(updatedTickets);
      setLoadingComponent(true);

      // getTickets()
    }
    setSelectedIds([]);
    setSelectedTicket(null);
    setSelectedUser(null);
    setIsLoading(false);
  };

  return (
    <>
      <section>
        <Heading text={"Assign Tickets"} />
        <Card>
          <SearchForm
            setSearchedData={setSearchedData}
            setIsLoading={setIsLoading}
            filters={{
              assignTo: null,
              reAssignTo: null,
              isOfficeClear: false,
              cancelDate: null,
              removeOr: true,
              ass_reass_req: true,
            }}
            loadDataOnMount={true}
            hiddenElements={[
              "assignTo",
              "typeOfWork",
              "isCompletedTicketOnly",
              "cancelDate",
            ]}
            from={"Assign"}
          />

          <Form noValidate>
            <Row className="pe-0">
              {/* <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>UnAssigned Tickets</Form.Label>
                    <Select
                      options={unAssignedTicket}
                      styles={customStyles}
                      defaultValue={unAssignedTicket[0]}
                      onChange={(selectedOption) => {
                        setSelectedTicket(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </Col> */}
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Assign To</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    defaultValue={users[0]}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col className="column" style={{ maxWidth: "185px" }}>
                <Form.Group
                  className="form-group"
                  style={{ minHeight: "44px", marginTop: "40px" }}
                >
                  <Form.Check
                    type="checkbox"
                    id="Click to view ticket detail"
                    label="Assign as per-Ticket"
                    name="isAssignPerTicket"
                    checked={checkedItems["isAssignPerTicket"] || false}
                    onChange={handleCheckboxChange}
                    className="include-check"
                  />
                </Form.Group>
              </Col>
              <Col className="mb-4 column" style={{ maxWidth: "142px" }}>
                <Button
                  style={{ minHeight: "44px", marginTop: "30px" }}
                  onClick={() => {
                    assignTicket();
                  }}
                >
                  <TbClipboardCheck />
                  Assign
                </Button>
              </Col>
            </Row>
          </Form>
          {successMsg && (
            <>
              <div className="successMsg">
                <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
                  {successMsg}
                </strong>
                <MdClose
                  size={20}
                  color={"gray"}
                  onClick={() => setSuccessMsg("")}
                  style={{ position: "absolute", top: 10, right: 10 }}
                />
              </div>
            </>
          )}
          {loadingComponent && (
            <SampleDataTable
              columns={columns}
              data={searchedData}
              onSelectionChange={handleSelectionChange}
            />
          )}

          <Footer />
        </Card>
      </section>
    </>
  );
};

export default AssignTIckets;
