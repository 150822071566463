import React from "react";
import { Table, Form } from "react-bootstrap";

const PermissionTable = ({ permissionData, checkedItems, setCheckedItems }) => {
  const handleCheckboxChange = (permissionId) => {
    setCheckedItems((currentCheckedItems) => {
      if (currentCheckedItems.includes(permissionId)) {
        // Remove item if it's already included
        return currentCheckedItems.filter((item) => item !== permissionId);
      } else {
        // Add item if it's not included
        return [...currentCheckedItems, permissionId];
      }
    });
  };

  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Permission</th>
          <th>Checkbox</th>
        </tr>
      </thead>
      <tbody>
        {permissionData.map((item) => (
          <tr key={item.id}>
            <td>{item.permission}</td>
            <td>
              <Form.Check
                type="checkbox"
                id={item.permission_id}
                name={item.permission}
                checked={checkedItems.includes(item.permission_id)}
                onChange={() => handleCheckboxChange(item.permission_id)}
                className="mb-3 d-flex align-items-center justify-content-end gap-1"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PermissionTable;
