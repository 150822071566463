import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const subscriptionCreated = createSlice({
  name: 'subscriptionCreated',
  initialState: {
    subscription : null
  },
  reducers: {
    setSubscription: (state,  { payload: { subscription } }) => {
      state.subscription = subscription;
    },    
  }
});


export default subscriptionCreated.reducer;

export const { setSubscription } = subscriptionCreated.actions