import Search from "Components/Search";
import React from "react";
import { Container } from "react-bootstrap";
import classes from "./index.module.scss"
import ProfileDropdown from "Components/ProfileDropdown";

const Header = () => {
  return (
    <header className={`exclude-print ${classes.header}`} >
      <Container fluid>
        <div className={"flex-box"}>
          <Search />
          <ProfileDropdown/>
        </div>
      </Container>
    </header>
  );
};

export default Header;
