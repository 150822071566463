const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
    user: null,
    userId: null,
    accessToken: null,
    refreshToken: null,
    department: null,
}
const auth = createSlice({
    name: "auth", initialState, reducers: {
        authSuccess: (state, { payload: { accessToken, refreshToken, userId, user } }) => {
            state.accessToken = accessToken
            state.refreshToken = refreshToken
            state.userId = userId
            state.user = user
        },
        logout: (state) => {
            state.accessToken = initialState.accessToken
            state.refreshToken = initialState.refreshToken
            state.userId = initialState.userId
            state.user = initialState.user
        },
        setUserDepartment: (state, { payload: { department } }) => {
            state.department = department
        }
    }
})

export default auth.reducer

export const { authSuccess, logout, setUserDepartment } = auth.actions