import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "redux/reducers/auth";
import { store } from "redux/store";

const clearLocalStorage = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const performLogout = () => {
  store.dispatch(logout())
  clearLocalStorage();
};

const redirectToLogin = () => {
  performLogout();
  setTimeout(() => {
    if (window.location.pathname != "/")
      window.location = "/"
  }, 3000)
}

const consoleErrorPerformRedirection = (error) => {
  console.error(error?.response?.data?.message || error);
  if (error?.response?.status === 401) {
    redirectToLogin();
    return
  }
  let errors = error?.response?.data?.errors?.length > 0 && Object.entries(error.response.data.errors[0])
  errors = errors && errors[0][1]
  toast.error(errors || error?.response?.data?.message || error.message, {
    position: "top-right",
    autoClose: 2000,
  });
};

//Get Method
const getMethod = async (
  endpoint,
  authentication = true,
  showError = true,
  data
) => {
  let params = {};
  let bearer_token;
  if (authentication) {
    bearer_token = localStorage.getItem("accessToken");

    params = {
      headers: {
        Authorization: `Bearer ${bearer_token}`,
        Accept: "application/json",
      },
    };
  }
  if (data) {
    params.params = data;
  }
  return await axios
    .get(endpoint, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (showError) {
        consoleErrorPerformRedirection(error);
      } else {
        return;
      }
    });
};

// Post Method
const postMethod = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false,
  showError = true
) => {
  let headers = {};

  if (authentication) {
    // const { auth } = store.getState();
    var bearer_token = localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
    headers["Accept"] = "application/json";
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  }
  return await axios
    .post(endpoint, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (showError) {
        consoleErrorPerformRedirection(error);
      }
    });
};

// Delete Method
const deleteMethod = async (endpoint, authentication = true, data = null) => {
  let headers;
  if (authentication) {
    var bearer_token = localStorage.getItem("accessToken");
    headers = {
      Authorization: `Bearer ${bearer_token}`,
    };
  }
  return await axios
    .delete(endpoint, { headers, data })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      consoleErrorPerformRedirection(error);
    });
};

//Patch Method
const patchMethod = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false
) => {
  let headers = {};

  if (authentication) {
    var bearer_token = localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  }
  return await axios
    .patch(endpoint, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      consoleErrorPerformRedirection(error);
    });
};

//Patch Method
const putMethod = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false
) => {
  let headers = {};

  if (authentication) {
    var bearer_token = localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  }
  return await axios
    .put(endpoint, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      consoleErrorPerformRedirection(error);
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getMethod,
  postMethod,
  deleteMethod,
  patchMethod,
  putMethod,
};
