import axios from "axios";
import * as XLSX from "xlsx-js-style";

export async function addressToLatLng(address) {
  try {
    const response = await axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json",
      {
        params: {
          address: address,
          key: "AIzaSyBqdKhAkPI_qCHco8_vVsnGrUGYjw7HL3M",
        },
      }
    );

    if (response.data.status === "OK") {
      const location = response.data.results[0].geometry.location;
      return location; // { lat: <latitude>, lng: <longitude> }
    } else {
      throw new Error("Geocoding failed: " + response.data.status);
    }
  } catch (error) {
    console.error("Error occurred while geocoding:", error);
    throw error;
  }
}

export function wrapLastTextInBracketsWithStrong(text) {
  const lastBracketIndex = text.lastIndexOf("(");
  if (lastBracketIndex !== -1) {
    const endBracketIndex = text.indexOf(")", lastBracketIndex);
    if (endBracketIndex !== -1) {
      const textBefore = text.substring(0, lastBracketIndex);
      const textInside = text.substring(lastBracketIndex, endBracketIndex + 1);
      const textAfter = text.substring(endBracketIndex + 1);
      return (
        <p>
          {textBefore}
          <strong>{textInside}</strong>
          {textAfter}
        </p>
      );
    }
  }
  return text;
}

export function exportFileToExcel(data, fileName) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Calculate column widths based on content length
  const colWidths = data[0].map((_, colIndex) => {
    const maxLength = Math.max(
      ...data.map((row) =>
        row[colIndex] ? row[colIndex].toString().length : 0
      )
    );
    return { wch: maxLength + 6 }; // Add padding for readability
  });

  // Set column widths
  worksheet["!cols"] = colWidths;

  // Define border style
  const borderStyle = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  // Iterate over all cells to apply styles (text wrapping and borders)
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_ref = XLSX.utils.encode_cell({ r: R, c: C });

      if (!worksheet[cell_ref]) continue; // Skip empty cells

      // Get existing cell style if it exists
      const existingStyle = worksheet[cell_ref].s || {};

      // Merge existing styles with new styles (text wrap and borders)
      worksheet[cell_ref].s = {
        ...existingStyle, // Preserve any existing styles
        alignment: {
          ...existingStyle.alignment,
          wrapText: true,
          vertical: "center",
          horizontal: "left",
        }, // Add text wrapping
        border: { ...existingStyle.border, ...borderStyle }, // Add borders, keep existing borders if any
      };
    }
  }

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  // Export the workbook
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}
