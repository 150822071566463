import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

// Create a slice to store the title and description
const pageSlice = createSlice({
  name: 'page',
  initialState: {
    title: '',
    description: ''
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    }
  }
});

// Create an async thunk to set the title and description
export const setPageData = createAsyncThunk(
  'page/setPageData',
  async (data, thunkAPI) => {
    const { title, description } = data;
    thunkAPI.dispatch(pageSlice.actions.setTitle(title));
    thunkAPI.dispatch(pageSlice.actions.setDescription(description));
  }
);


export default pageSlice.reducer;