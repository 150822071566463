import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createRegion = async (data) => {
  return await API.postMethod(ENDPOINT.region.create, true, data);
};

const getRegion = async () => {
  return await API.getMethod(ENDPOINT.region.get, true);
};

const getRegionById = async (id) => {
  return await API.getMethod(`${ENDPOINT.region.get}/${id}`, true);
};

const updateRegionById = async (id, data) => {
  return await API.putMethod(`${ENDPOINT.region.update}/${id}`, true, data);
};

const deleteRegionById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.region.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createRegion,
  getRegionById,
  getRegion,
  updateRegionById,
  deleteRegionById,
};
