import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "Components/Logo"; // Adjust the import path accordingly
import icon1 from "../../Images/icon/icon5.svg";
import ticket from "../../Images/icon/icon1.svg";
import complaint from "../../Images/icon/icon7.svg";
import creditcard from "../../Images/icon/icon6.svg";
import oustanding from "../../Images/icon/icon4.svg";
import ticketUser from "../../Images/icon/icon3.svg";
import dapartment from "../../Images/icon/icon2.svg";
import dot from "../../Images/doticon.svg";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  TbLayoutSidebarLeftCollapseFilled,
  TbLayoutSidebarRightCollapseFilled,
} from "react-icons/tb";

const Sidebar = () => {
  const location = useLocation();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [activeMainItem, setActiveMainItem] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const { department } = useSelector((state) => state.auth);
  const handleSidebarCollapseClick = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  if (department && department.permissions[0]) {
    const obj = {};
    const arr = [...department.permissions];
    arr.forEach((item) => {
      obj[`${item}`] = true;
    });

    var {
      assign_ticket,
      assign_tickey_by_map,
      office_clear,
      close_tickets,
      outstanding_tickets,
      reassign_tickets,
      create_new_tickets,
      alert_messages,
      hours_log,
      query_search_ticket,
      payroll_audit,
      user_setup,
      department_permissions,
      billing,
      non_compliance_report,
      reschedule_revoke,
      content_management,
    } = obj;
  } else if (department === null) {
    var {
      assign_ticket = true,
      assign_tickey_by_map = true,
      office_clear = true,
      close_tickets = true,
      outstanding_tickets = true,
      reassign_tickets = true,
      create_new_tickets = true,
      alert_messages = true,
      hours_log = true,
      query_search_ticket = true,
      payroll_audit = true,
      user_setup = true,
      department_permissions = true,
      billing = true,
      non_compliance_report = true,
      reschedule_revoke = true,
      content_management = true,
    } = {};
  }

  const handleMouseEnter = () => {
    if (isSidebarCollapsed) {
      setSidebarCollapsed(false);
    }
  };
  const handleMouseLeave = () => {
    setSidebarCollapsed(true);
  };

  const handleMainItemClick = (id) => {
    setActiveMainItem((prevId) => (prevId === id ? null : id));
    setActiveSubmenu(null);
  };

  const handleSubmenuItemClick = (path) => {
    setActiveSubmenu((prevPath) => (prevPath === path ? null : path));
  };

  const links = [
    {
      id: 1,
      text: "Dashboard",
      icon: ticketUser,
      show:
        (department?.name == "Tester" ||
          department?.name == "admin" ||
          department?.name == "manager") &&
        true,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      path: "/admin-dashboard",
    },
    {
      id: 2,
      text: "Request Allocation",
      icon: ticket,
      show: office_clear && true,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      submenu: [
        {
          id: 10,
          text: "Office Clear",
          path: "/dashboard/office-clear",
          icon: dot,
          show: office_clear && true,
        },
      ],
    },
    {
      id: 3,
      text: "Manage Ticket",
      icon: dapartment,
      show:
        query_search_ticket ||
        reassign_tickets ||
        assign_ticket ||
        assign_tickey_by_map ||
        outstanding_tickets,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      submenu: [
        {
          text: "Query/Search Ticket",
          path: "/dashboard/query-search",
          icon: dot,
          show: query_search_ticket && true,
        },
        {
          text: "Assign Tickets",
          path: "/dashboard/assign-ticket",
          icon: dot,
          show: assign_ticket && true,
        },
        {
          text: "Assign Tickets By Map",
          path: "/dashboard/assign-tickets-by-map",
          icon: dot,
          show: assign_tickey_by_map && true,
        },
        {
          text: "Reassign Tickets",
          path: "/dashboard/reassign-ticket",
          icon: dot,
          show: reassign_tickets && true,
        },
        {
          text: "Reassign Tickets By Map",
          path: "/dashboard/reassign-tickets-by-map",
          icon: dot,
          show: reassign_tickets && true,
        },
        {
          text: "Close Tickets",
          path: "/dashboard/close-tickets",
          icon: dot,
          show: close_tickets && true,
        },
        {
          text: "Outstanding Tickets",
          path: "/dashboard/outstanding-tickets",
          icon: dot,
          show: outstanding_tickets && true,
        },
      ],
    },
    {
      id: 4,
      text: "Admin Management",
      icon: ticketUser,
      show: create_new_tickets || close_tickets,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      submenu: [
        // {
        //   text: "Create New Tickets (Ontario One)",
        //   path: "/dashboard/create-new-tickets-ontario-one",
        //   icon: dot,
        //   show: create_new_tickets && true,
        // },
        {
          text: "Create New Tickets (Private)",
          path: "/dashboard/create-new-tickets-local",
          icon: dot,
          show: create_new_tickets && true,
        },

        // {
        //   text: "Outstanding Tickets",
        //   path: "/dashboard/outstanding-tickets",
        //   icon: dot,
        //   show: outstanding_tickets && true,
        // },
        // {
        //   id: 6,
        //   text: "Alert Messages",
        //   path: "/dashboard/alert-messages",
        //   icon: dot,
        //   show: alert_messages && true,
        // },
        // {
        //   text: "Ticket Detail",
        //   path: "/dashboard/ticket-detail",
        //   icon: dot,
        //   show: outstanding_tickets && true,
        // },
      ],
    },
    {
      id: 5,
      text: "My Profile",
      icon: oustanding,
      show: query_search_ticket || hours_log || payroll_audit,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      submenu: [
        {
          text: "Hours Log",
          path: "/dashboard/hours-log",
          icon: dot,
          show: hours_log,
        },
        {
          text: "Payroll Audit",
          path: "/dashboard/payroll-audit",
          icon: dot,
          show: payroll_audit && true,
        },
      ],
    },
    {
      id: 6,
      text: "Manage Content",
      icon: icon1,
      show: department_permissions || user_setup || content_management,
      dropIcon: <IoIosArrowDropdownCircle size={"16px"} />,
      submenu: [
        {
          text: "User Setup",
          path: "/dashboard/user-setup",
          icon: dot,
          show: user_setup && true,
        },
        {
          text: "Department Permission",
          path: "/dashboard/department-permission",
          icon: dot,
          show: department_permissions && true,
        },
        // {
        //   text: "Cities",
        //   path: "/dashboard/city",
        //   icon: dot,
        //   show: department_permissions && true,
        // },
        {
          text: "Station Code",
          path: "/dashboard/station-codes",
          icon: dot,
          show: content_management && true,
        },
        {
          text: "Document Type",
          path: "/dashboard/doc-type",
          icon: dot,
          show: content_management && true,
        },
        {
          text: "Reason",
          path: "/dashboard/reason",
          icon: dot,
          show: content_management && true,
        },
        {
          text: "Region",
          path: "/dashboard/region",
          icon: dot,
          show: content_management && true,
        },
        {
          text: "Ticket Types",
          path: "/dashboard/ticket-types",
          icon: dot,
          show: content_management && true,
        },
        {
          text: "Departments",
          path: "/dashboard/departments",
          icon: dot,
          show: content_management && true,
        },
      ],
    },
    {
      id: 7,
      text: "Billing",
      path: "/dashboard/billing",
      icon: creditcard,
      show: billing && true,
    },
    {
      id: 8,
      text: "Non-Compliance Report",
      path: "/dashboard/non-compliance-report",
      icon: complaint,
      show: non_compliance_report && true,
    },
    {
      id: 9,
      text: "Reschedule Revoke Ticket",
      path: "/dashboard/reschedule-revoke",
      icon: complaint,
      show: non_compliance_report && true,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setSidebarCollapsed(true);
      } else {
        setSidebarCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`sidebar exclude-print ${
        isSidebarCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="text-center mb-3" onClick={handleSidebarCollapseClick}>
        {isSidebarCollapsed ? (
          <TbLayoutSidebarRightCollapseFilled size={"32px"} />
        ) : (
          <TbLayoutSidebarLeftCollapseFilled size={"32px"} />
        )}
      </div>
      <div
        className="w-100 inner-sidebar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Logo />
        <ul className="nav">
          {links.map(
            (link) =>
              link.show && (
                <li
                  key={link.path || link.text}
                  className={`nav-item ${
                    activeMainItem === link.id ? "active" : ""
                  }`}
                >
                  {link.submenu ? (
                    <>
                      <span
                        className={`submenu-header ${
                          activeMainItem === link.id ? "active" : ""
                        }`}
                        onClick={() => handleMainItemClick(link.id)}
                      >
                        <img src={link.icon} alt="icon" />
                        {link.text}
                        <span className="float-end drop-icon">
                          {" "}
                          {link.dropIcon}
                        </span>
                      </span>
                      {/* Always render the submenu */}
                      <ul className={"submenu"}>
                        {link.submenu.map(
                          (submenuItem) =>
                            submenuItem.show && (
                              <li
                                key={submenuItem.path}
                                className={`nav-item ${
                                  activeSubmenu === submenuItem.path
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSubmenuItemClick(submenuItem.path)
                                }
                              >
                                <Link to={submenuItem.path}>
                                  <img src={submenuItem.icon} alt="icon" />
                                  {submenuItem.text}
                                </Link>
                              </li>
                            )
                        )}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={link.path}
                      onClick={() => handleMainItemClick(link.id)}
                    >
                      <img src={link.icon} alt="icon" />
                      {link.text}
                    </Link>
                  )}
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
