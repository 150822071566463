import React, { useEffect, useMemo, useState } from "react";
import Heading from "Components/Heading"; // Assuming Heading is a React component
import Footer from "Components/Footer"; // Assuming Footer is a React component
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import classes from "./index.module.scss";
import img1 from "../../Images/dash1.svg";
import SearchIcon from "@mui/icons-material/Search";
import img2 from "../../Images/dash2.svg";
import img3 from "../../Images/dash3.svg";
import img4 from "../../Images/dash4.svg";
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js"; // Import CategoryScale
import { Chart, registerables } from "chart.js";
import SampleDataTable from "Components/DataTables";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import { BiDownload, BiExport, BiLike } from "react-icons/bi";
import { formatDate } from "Helper/Converters";
import { useSelector } from "react-redux";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import * as XLSX from "xlsx-js-style";
import moment from "moment";
import { exportFileToExcel } from "Utils/utils";
import OfficeClearChart from "./officeClearStats";
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "44px",
  }),
};

const columns = [
  {
    accessor: "ticketNo",
    Header: "Ticket No",
    Cell: ({ row }) => (
      <a
        onClick={(e) => {
          e.preventDefault();
          const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
          // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
          const windowName = `popUpWindow_${row.original.ticketNo}`;
          const windowSize = "width=800,height=600";
          window.open(url, windowName, windowSize);
        }}
        href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
        rel="noopener noreferrer"
        className={`badge ${row.original.ticketType == "EMERGENCY" ||
          row.original.priority == "EMERGENCY"
          ? "emergency"
          : row.original.ticketType == "MULTIPLE" ||
            row.original.priority == "MULTIPLE"
            ? "multiple"
            : row.original.ticketType == "PRIORITY" ||
              row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
                ? "projectWork"
                : row.original.ticketType == "STANDARD" ||
                  row.original.priority == "STANDARD"
                  ? "standard"
                  : "default"
          }`}
      >
        {row.original.ticketNo}
      </a>
    ),
  },
  { accessor: "address", Header: "Location" },
  {
    Header: "Entered Date",
    Cell: ({ row }) => (
      <p>{row.original.createdAt ? formatDate(row.original.createdAt) : ""}</p>
    ),
  },
  {
    Header: "Assigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.assignDate
          ? moment(row.original.assignDate).format("ll")
          : ""}
      </p>
    ),
  },
  {
    Header: "Reassigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.reAssignDate
          ? moment(row.original.reAssignDate).format("ll")
          : ""}
      </p>
    ),
  },
  {
    Header: "Station Code",
    Cell: ({ row }) => <p>{row.original.memberStationCode}</p>,
  },
];

const stationCodeCompletionColumns = [
  {
    Header: "Station Code",
    Cell: ({ row }) => <p>{row.original.stationCode}</p>,
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedTickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompleteTickets}</p>,
  },
];

const userStatsColumns = [
  {
    Header: "Name",
    Cell: ({ row }) => (
      <p>{row.original.firstname ? row.original.firstname : "None"}</p>
    ),
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedtickets}</p>,
  },
  {
    Header: "Pre Completed",
    Cell: ({ row }) => <p>{row.original.precompletedtickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompletetickets}</p>,
  },
  {
    Header: "Late",
    Cell: ({ row }) => <p>{row.original.rescheduledaftercompletion}</p>,
  },
  {
    Header: "Emergency",
    Cell: ({ row }) => <p>{row.original.emergencytickets}</p>,
  },
];

const options = {
  // ... other options
  indexAxis: "y",
  plugins: {
    legend: {
      display: true, // Show the legend
      position: "bottom", // Position of the legend (for bar chart)
      labels: {
        usePointStyle: true, // Use dataset's point style for legend
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
      horizontal: true,
    },
  },
};

const options2 = {
  scales: {
    yAxes: [
      {
        id: "y1",
        position: "left",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
      {
        id: "y2",
        position: "right",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
    ],
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        usePointStyle: true,
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
    },
  },
};

Chart.register(...registerables);

const DROPDOWN_DATA = [
  { label: "TODAY", value: "TODAY" },
  { label: "WEEK", value: "WEEK" },
  { label: "MONTH", value: "MONTH" },
  { label: "YEAR", value: "YEAR" },
];

const AdminDashboard = () => {
  const { department } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  // const [selectedOption, setSelectedOption] = useState(DROPDOWN_DATA[0]);

  const getData = async () => {
    setLoading(true);
    const res = await TicketAPIs.adminDashboardStats(startDate, endDate);
    if (res?.data?.success) {
      const userStats =
        res.data.data?.userStats?.filter((item) => item.firstname) || [];
      setData({ ...res.data.data, userStats });
    }
    console.log("data dump: ", res.data.data)
    setLoading(false);
  };

  // Excel complaint sheet 
  const formatDateForExcelCompliantTickets = () => {
    let formattedData = [
      [
        {
          v: "Ticket #",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Address",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Contractor",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Ticket Type",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Entered Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Assigned To",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Locate City",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Caller Name",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Contractor Phone",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
      ],
    ];
    data?.compliantTickets.forEach((item) => {
      formattedData.push([
        { v: item.ticketNo, s: { alignment: { wrapText: true } } },
        { v: `${item.digAddress} ${item.region}`, s: { alignment: { wrapText: true } } },
        { v: item.company_name, s: { alignment: { wrapText: true } } },
        { v: item.priority, s: { alignment: { wrapText: true } } },
        { v: item.createdAt ? formatDate(item.createdAt) : "", s: { alignment: { wrapText: true } } },
        {
          v: item.assignTo
            ? `${item.assignTo.firstName} ${item.assignTo.lastName}`
            : "", s: { alignment: { wrapText: true } }
        },
        { v: item.digCity, s: { alignment: { wrapText: true } } },
        { v: item.contractorName, s: { alignment: { wrapText: true } } },
        { v: item.phone, s: { alignment: { wrapText: true } } },
        { v: item.rescheduleDate ? formatDate(item.rescheduleDate) : "", s: { alignment: { wrapText: true } } },
        {
          v: item.dueDate ? formatDate(item.dueDate) : "", s: { alignment: { wrapText: true } }
        },
      ]);
    });

    return formattedData;
  };


  // Excel compliant sheet for 3days or less 
  const formatDateForExcelCompliantTickets3DaysOrLess = () => {
    let formattedData = [
      [
        {
          v: "Ticket #",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Address",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Contractor",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Ticket Type",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Entered Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Assigned To",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Locate City",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Caller Name",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Contractor Phone",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: {
            font: { bold: true, sz: 16, color: { rgb: "0068af" } },
            alignment: { wraptext: true }
          },
        },
      ],
    ];
    data?.compliantTickets3DaysOrLess.forEach((item) => {
      formattedData.push([
        { v: item.ticketNo, s: { alignment: { wrapText: true } } },
        { v: `${item.digAddress} ${item.region}`, s: { alignment: { wrapText: true } } },
        { v: item.company_name, s: { alignment: { wrapText: true } } },
        { v: item.priority, s: { alignment: { wrapText: true } } },
        { v: item.createdAt ? formatDate(item.createdAt) : "", s: { alignment: { wrapText: true } } },
        {
          v: item.assignTo
            ? `${item.assignTo.firstName} ${item.assignTo.lastName}`
            : "", s: { alignment: { wrapText: true } }
        },
        { v: item.digCity, s: { alignment: { wrapText: true } } },
        { v: item.contractorName, s: { alignment: { wrapText: true } } },
        { v: item.phone, s: { alignment: { wrapText: true } } },
        { v: item.rescheduleDate ? formatDate(item.rescheduleDate) : "", s: { alignment: { wrapText: true } } },
        {
          v: item.dueDate ? formatDate(item.dueDate) : "", s: { alignment: { wrapText: true } }
        },
      ]);
    });


    return formattedData;
  };

  const exportToExcel = (data) => {
    const fileName = `Compliant Report ${new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }) +
      "-" +
      new Date(endDate).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
      }`;

    const tab1Data = data;
    const tab2Data = formatDateForExcelCompliantTickets3DaysOrLess();

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to worksheet
    const worksheet1 = XLSX.utils.aoa_to_sheet(tab1Data);
    const worksheet2 = XLSX.utils.aoa_to_sheet(tab2Data);

    // Cell widths
    const uniformColumnWidth = 30; // Width in characters
    const numberOfColumns = tab2Data[0].length; // first row
    worksheet1['!cols'] = Array(numberOfColumns).fill({ width: uniformColumnWidth });
    worksheet2['!cols'] = Array(numberOfColumns).fill({ width: uniformColumnWidth });

    // Cell Height 
    const uniformRowHeight = 40; // Height in pixels
    worksheet1['!rows'] = Array(tab2Data.length).fill({ hpx: uniformRowHeight });
    worksheet2['!rows'] = Array(tab2Data.length).fill({ hpx: uniformRowHeight });

    // Append worksheets to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Non-Compliant Tickets");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "3 Days or Less");

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  useEffect(() => {
    getData();
  }, []);

  const stationCodeStatsData = useMemo(() => {
    const stationCodes =
      data?.stationCodes?.map((item) => item.stationCode) || [];
    const completedTickets =
      data?.stationCodes?.map((item) => Number(item.completedTickets)) || [];
    const incompleteTickets =
      data?.stationCodes?.map((item) => Number(item.incompleteTickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: stationCodes,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Reassigned Tickets Count", // Optional custom label for legend
          },
        },
      ],
    };
  }, [data]);

  const userStatsData = useMemo(() => {
    const userLabel = data?.userStats?.map((item) => item.firstname || "None");

    const completedTickets =
      data?.userStats?.map((item) => Number(item.completedtickets)) || [];
    const incompleteTickets =
      data?.userStats?.map((item) => Number(item.incompletetickets)) || [];
    const lateTickets =
      data?.userStats?.map((item) => Number(item.rescheduledaftercompletion)) ||
      [];
    const emergencyTickets =
      data?.userStats?.map((item) => Number(item.emergencytickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: userLabel,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Incomplete Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Late Tickets",
          data: lateTickets,
          backgroundColor: "red",
          borderColor: "red",
          borderWidth: 0,
          metadata: {
            customLabel: "Late Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Emergency Tickets",
          data: emergencyTickets,
          backgroundColor: "yellow",
          borderColor: "yellow",
          borderWidth: 0,
          metadata: {
            customLabel: "Emergency Tickets Count", // Optional custom label for legend
          },
        },
      ],
    };
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <section>
          <div className="d-flex justify-content-between">
            <Heading text={"Admin Dashboard"} />
            <Form.Group className="d-flex form-group date-range-picker me-2">
              <div className="me-3">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="form-control"
                />
              </div>

              <div className="me-2">
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                  className="form-control"
                />
              </div>
              <div
                className="search-icon-date-selection"
                onClick={() => getData()}
              >
                <SearchIcon />
              </div>
            </Form.Group>
          </div>
          <Row className=" align-items-stretch">
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.totalTickets}</h3>
                  <h6 className="text-muted">Total Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.completedTickets}</h3>
                  <h6 className="text-muted">Completed Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.assignedOrReassignedTickets}</h3>
                  <h6 className="text-muted">Assigned Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.compliantTickets.length}</h3>
                  <h6 className="text-muted">Compliant Tickets</h6>
                </div>
                <span
                  role="button"
                  className="ms-3 ms-md-4 cursor-pointer"
                  onClick={() =>
                    exportToExcel(formatDateForExcelCompliantTickets())
                  }
                >
                  <BiDownload color={"#0073ba"} size={24} />
                </span>
              </Card>
            </Col>
            {/* <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.compliantTickets.length}</h3>
                  <h6 className="text-muted">Office Clear Tickets</h6>
                </div>
                  <span role="button" className="ms-3 ms-md-4 cursor-pointer" onClick={()=>exportToExcel(formatDateForExcelOCTickets())}>
                        <BiDownload color={'#0073ba'} size={24}/>
                      </span>
              </Card>
            </Col> */}
          </Row>
          {(department?.name == "manager" || department?.name == "locator") && (
            <Row className="mb-3 align-items-stretch">
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Station Codes</h6>
                  <SampleDataTable
                    columns={stationCodeCompletionColumns}
                    data={data?.stationCodes || []}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Statistics</h6>
                  <Bar data={stationCodeStatsData} options={options} />
                </Card>
              </Col>
            </Row>
          )}
          {(department?.name == "admin" || department?.name == "Tester") && (
            <>
              <Row className="mb-3 align-items-stretch">
                <Col lg={6}>
                  <Card className="h-100">
                    <h6>User Tickets Stats</h6>
                    <SampleDataTable
                      columns={userStatsColumns}
                      data={data?.userStats || []}
                      resultsTitle="Total Users Found"
                      noDataMsg="No Users Found"
                    />
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="h-100">
                    <h6>User Statistics</h6>
                    <Bar data={userStatsData} options={options2} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card className="h-100 p-3">
                    <OfficeClearChart
                      remainingTicketsToBeOfficeCleared={
                        data?.officeClearTicketsStats
                          ?.remainingTicketsToBeOfficeCleared
                      }
                      sendToLocate={data?.officeClearTicketsStats?.sendToLocate}
                      totalTicketsInOfficeClear={
                        data?.officeClearTicketsStats?.totalTicketsInOfficeClear
                      }
                      totalTicketsOfficeClearedToday={
                        data?.officeClearTicketsStats
                          ?.totalTicketsOfficeClearedToday
                      }
                    />
                  </Card>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </>
          )}
          {(department?.name == "manager" || department?.name == "locator") && (
            <Card>
              <Card>
                <h6>Latest Tickets</h6>
                <SampleDataTable
                  columns={columns}
                  data={data?.latestTickets || []}
                />
              </Card>
              <Footer />
            </Card>
          )}
        </section>
      )}
    </>
  );
};

export default AdminDashboard;
