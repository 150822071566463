import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Footer from "Components/Footer";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import { IoSaveOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router";
import { MdDeleteOutline } from "react-icons/md";
import { BiUpload } from "react-icons/bi";
import { useParams } from "react-router-dom";
import FileAPIs from "../../APIs/file";
import AuthAPIs from "../../APIs/auth";
import DocTypeAPIs from "../../APIs/doc-type";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "Components/Loader";
import Swal from "sweetalert2";
import axios from "axios";
import { ENDPOINT } from "config/constants";
import ReactDatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";

const EditUser = () => {
  const { id } = useParams();
  const [checkedItems, setCheckedItems] = useState({});
  const [departments, setDepartments] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [filesList, setFilesList] = useState([]);
  const [userFiles, setUserFiles] = useState([]);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [newUserDocuments, setNewUserDocuments] = useState([]);
  const [customDocumentId, setCustomDocumentId] = useState(0);
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("USER_DOC");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };
  const navigation = useNavigate();

  const getAllDocTypes = async () => {
    const types = await DocTypeAPIs.getDocType();

    const customArr = [];
    if (types) {
      const arr = [...types.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setDocTypes(customArr);
    }
  };

  const options = [
    { value: "Criminal Record Check", label: "Criminal Record Check" },
    { value: "Drivers Abstract", label: "Drivers Abstract" },
    { value: "Drivers Licence", label: "Drivers Licence" },
  ];

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Login name is required"),
    // password: Yup.string().required("Password is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    provience: Yup.string().required("Provience is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(
        /^\+?[0-9]+$/,
        "Phone must contain only digits and an optional leading +"
      )
      .min(8, "Phone must be at least eight characters long"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required."),
    emergencyPhoneNumber: Yup.string().required(
      "Emergency Phone Number is required"
    ),
    dob: Yup.date().required("DOB is required"),
    department: Yup.string().required("Department is required"),
    documentType: Yup.string().required("Document Type is required"),
  });

  useEffect(() => {
    getDepartments();
    getUserFiles();
    getAllDocTypes();
  }, []);

  const getDepartments = async () => {
    const dep = await AuthAPIs.getDepartments();
    if (dep) {
      const customArr = [];
      const arr = [...dep.data.data];
      arr.forEach((item) => {
        const data = { value: item?.id, label: item?.name };
        customArr.push(data);
      });
      setDepartments(customArr);
    }
  };

  useEffect(() => {
    getUser(id);
  }, [id]);

  const getUser = async (id) => {
    setIsLoading(true);
    if (id) {
      const user = await AuthAPIs.getUserById(id);
      if (user) {
        setUser(user.data.data);
        if (user.data.data.otherDocuments) {
          setFilesList(user.data.data.otherDocuments);
        }
      }
    }
    setIsLoading(false);
  };

  const uploadFileHandler = async (id, file) => {
    if (id) {
      const data = new FormData();
      data.append("attachments", file);
      const uploaded = await FileAPIs.uploadFileWithRole("user", id, data);
    }
  };

  const appendFileInAPIState = () => {
    if (selectedFile) {
      selectedFile["customId"] = customDocumentId;
      setNewUserDocuments((prev) => [...prev, selectedFile]);
      setFilesList((prev) => [...prev, selectedFile]);
      setSelectedFile(null);
      setCustomDocumentId(customDocumentId + 1);
    } else {
      toast.error("No File Selected");
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("files", selectedFile);
    var bearer_token = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${ENDPOINT.file.uploadFile}/${id}/${selectedDocType}/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bearer_token}`,
          },
        }
      );

      toast.success("File uploaded successfully");
      getUserFiles();
    } catch (error) {
      toast.error(`File not uploaded: ${error.message}`);
    }
  };

  const getUserFiles = async () => {
    if (id) {
      const files = await FileAPIs.getFiles({
        userId: [id],
        isAllFiles: false,
      });

      if (files) {
        setUserFiles(files.data.data);
      }
    }
  };

  const fileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  const deleteFile = async (item) => {
    if (item.id) {
      const files = await FileAPIs.deleteFile(item.id);

      if (files) {
        getUserFiles();
      }
    }
  };

  const submitHandler = async (values) => {
    const data = {
      email: values.email,
      phoneNumber: values.phone,
      address: values.address,
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      city: values.city,
      postalCode: values.postalCode,
      provience: values.provience,
      documentType: values.documentType,
      emergencyPhoneNumber: values.emergencyPhoneNumber,
      dob: moment(values.dob).format("YYYY-MM-DD"),
      isActive: values.isActive,
      department: values.department,
    };
    if (values.password) {
      data["password"] = values.password;
    }
    const updated = await AuthAPIs.updateUser(id, data);
    if (updated) {
      toast.success("User Updated Successfully");
      if (newUserDocuments && newUserDocuments[0]) {
        newUserDocuments.forEach(async (doc) => {
          await uploadFileHandler(id, doc);
        });
      }
      /**
       * Deleting Files from Backend
       */
      if (deletedDocuments && deletedDocuments[0]) {
        deletedDocuments.forEach(async (id) => {
          await FileAPIs.deleteFile(id);
        });
      }

      navigate("/dashboard/user-setup");
    }
  };

  const deleteUser = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const del = await AuthAPIs.deleteUser(user?.id);
        if (del) {
          Swal.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
          navigate("/dashboard/user-setup");
        }
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Manage User"} />

          <Card>
            <Formik
                onSubmit={(values) => {
                submitHandler(values);
              }}
              initialValues={{
                email: user?.email,
                password: "",
                username: user?.username,
                firstName: user?.firstName,
                lastName: user?.lastName,
                address: user?.address,
                city: user?.city,
                postalCode: user?.postalCode,
                provience: user?.provience,
                phone: user?.phoneNumber,
                emergencyPhoneNumber: user?.emergencyPhoneNumber,
                dob: user?.dob ? new Date(user.dob) : null,
                isActive: user?.isActive,
                department: user?.department?.id,
                documentType: user?.documentType,
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
              }) => (
                <Form
                  className="dashboard-form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  {/* <Row>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Users</Form.Label>
                        <Select
                          options={options}
                          styles={customStyles}
                          defaultValue={options[0]}
                          isDisabled
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      className="column"
                      style={{ maxWidth: "242px", marginTop: "28px" }}
                    >
                      <Button
                        className="me-3"
                        onClick={() => navigation("/dashboard//user-setup/edit")}
                      >
                        <FaRegEdit />
                        Edit
                      </Button>
                      <Button 
                      onClick={()=> navigation("/dashboard/user-setup/add")}
                      >
                        <AiOutlinePlus />
                        Add
                      </Button>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={12} className="my-3">
                      <Form.Label style={{ fontSize: "14px", color: "#000" }}>
                        Employee Number{" "}
                        <span style={{ color: "#757575" }}>{user?.id}</span>
                      </Form.Label>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId="usernameGroup"
                        className="form-group"
                      >
                        <Form.Label htmlFor="username">Login Name</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"username"}
                          id={"username"}
                          placeholder="Kharshad"
                          value={values.username}
                          required
                          isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"password"}
                          name={"password"}
                          placeholder="************"
                          value={values.password}
                          required
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"firstName"}
                          placeholder="Kharshad"
                          value={values.firstName}
                          required
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"lastName"}
                          placeholder="Arshad"
                          value={values.lastName}
                          required
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"address"}
                          placeholder="950 Portage Parkway"
                          value={values.address}
                          required
                          isInvalid={!!errors.address}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"city"}
                          value={values.city}
                          required
                          isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"postalCode"}
                          value={values.postalCode}
                          required
                          isInvalid={!!errors.postalCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.postalCode}
                        </Form.Control.Feedback>{" "}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Provience</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"text"}
                          name={"provience"}
                          value={values.provience}
                          required
                          isInvalid={!!errors.provience}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.provience}
                        </Form.Control.Feedback>{" "}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"tel"}
                          name={"phone"}
                          value={values.phone}
                          required
                          isInvalid={!!errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>{" "}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"email"}
                          name={"email"}
                          value={values.email}
                          required
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Emergency Phone Number</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          type={"tel"}
                          name={"emergencyPhoneNumber"}
                          placeholder={"03223247819"}
                          value={values.emergencyPhoneNumber}
                          required
                          isInvalid={!!errors.emergencyPhoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.emergencyPhoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>DOB</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            selected={values.dob}
                            onSelect={(date) =>
                              handleChange({
                                target: { name: "dob", value: date },
                              })
                            }
                            value={values.dob}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM/dd/yyyy"
                            maxDate={new Date()}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {errors.dob && (
                          <span className="errorShow">{errors.dob}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <div className="checkboxHolder">
                          <Form.Check
                            type="checkbox"
                            id="isActive"
                            label="Active/Deactive"
                            name="isActive"
                            checked={values.isActive || false}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-2">
                      <Form.Group className="form-group">
                        <Form.Label>Department</Form.Label>
                        <Select
                          options={departments}
                          styles={customStyles}
                          defaultValue={user?.department?.name}
                          value={departments.find(
                            (dep) => dep.value === values.department
                          )}
                          onChange={(selectedOption) => {
                            handleChange("department")(selectedOption.value);
                          }}
                        />
                        <p className="errorShow"> {errors.department}</p>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button type="submit" className="me-3">
                        <IoSaveOutline />
                        Save
                      </Button>
                      <Button onClick={deleteUser}>
                        <MdDeleteOutline />
                        Delete
                      </Button>
                    </Col>
                    <Col>
                      <hr
                        style={{ margin: "20px 0", border: "1px solid #ccc" }}
                      />
                    </Col>
                    <Col sm={12} className="mb-2">
                      <Form.Group className="form-group">
                        <Form.Label>Document Type</Form.Label>
                        <Select
                          options={docTypes}
                          styles={customStyles}
                            onChange={(selectedOptions) => {
                            setSelectedDocType(selectedOptions.label);
                            setFieldValue(
                              "documentType",
                              selectedOptions.label
                            );
                          }}
                        />
                      </Form.Group>
                      <p className="errorShow"> {errors.documentType}</p>
                    </Col>
                    <Col className="column">
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label
                          htmlFor="fileInput"
                          className="browse-button"
                        >
                          <Button onClick={handleBrowseClick}>Browse</Button>
                          {selectedFile?.name}
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="fileInput"
                          className="d-none"
                          onChange={fileInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mb-4 column" style={{ maxWidth: "145px" }}>
                      <Button
                        style={{ minHeight: "44px" }}
                        onClick={handleUpload}
                      >
                        <BiUpload />
                        Upload
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul className="documentList">
                        <li>
                          <h6>Document</h6>
                        </li>
                        {userFiles ? (
                          userFiles?.map((item, ind) => {
                            return (
                              <li>
                                <>
                                  <a
                                    style={{ width: 300 }}
                                    href={item?.presignedUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    <h5>{item?.fileName || item?.name}</h5>
                                  </a>
                                  <p>{item?.metaData}</p>
                                </>

                                <Button
                                  style={{
                                    minHeight: "35px",
                                    padding: "0 8px",
                                    lineHeight: "35px",
                                  }}
                                >
                                  <span className="no-margin">
                                    <FaTimes
                                      onClick={() => {
                                        deleteFile(item);
                                      }}
                                    />
                                  </span>
                                </Button>
                              </li>
                            );
                          })
                        ) : (
                          <p className="text-center pt-3">
                            No Documents Uploaded
                          </p>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <Footer />
          </Card>
        </section>
      )}
      x
    </>
  );
};

export default EditUser;
