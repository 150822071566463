// ProductTable.js
import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import classes from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const UserTable = (users) => {
    const [usersData, setusersData] = useState([]);
    const { userId } = useSelector((state) => state.auth);

    const navigation = useNavigate();
    
    useEffect(() => {
        if(users){
            const filteredUsers = users?.users?.filter(user => user.id !== userId );
            setusersData(filteredUsers);
        }
    },[users])


    const navigateToEdit = (selectedUser) => {
        if (selectedUser) {
          navigation(`/dashboard/user-setup/edit/${selectedUser}`)
        }
      }
    



    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Login name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>City</th>
                        <th>Phone Number</th>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {usersData?.map((user, ind) => (
                        <tr key={user?.id}>
                            <td>{ind + 1}</td>
                            <td>{user?.firstName}</td>
                            <td>{user?.email}</td>
                            <td>{user?.department?.name}</td>
                            <td>{user?.city}</td>
                            <td>{user?.phoneNumber}</td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <HiOutlineDotsHorizontal />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1" onClick={()=>{navigateToEdit(user?.id)}} >edit</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default UserTable;
