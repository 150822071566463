import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const createDepartment = async (data) => {
  return await API.postMethod(ENDPOINT.department.create, true, data);
};

const getDepartments = async () => {
  return await API.getMethod(ENDPOINT.department.get, true);
};

const getDepartmentById = async (id) => {
  return await API.getMethod(`${ENDPOINT.department.get}/${id}`, true);
};

const updateDepartmentById = async (id, data) => {
  return await API.putMethod(`${ENDPOINT.department.update}/${id}`, true, data);
};

const deleteDepartmentById = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.department.update}/${id}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createDepartment,
  getDepartmentById,
  getDepartments,
  updateDepartmentById,
  deleteDepartmentById,
};
