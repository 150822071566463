import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Footer from "Components/Footer";
import { IoSaveOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router";
import { BiUpload } from "react-icons/bi";
import * as Yup from "yup";
import { Formik } from "formik";
import AuthAPIs from "../../APIs/auth";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import FileAPIs from "../../APIs/file";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";

const AddUser = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [departments, setDepartments] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [filesList, setFilesList] = useState([]);
  const navigate = useNavigate();
  const [uploadFilesAPI, setUploadFileAPI] = useState([]);
  const [documentId, setDocumentId] = useState(0);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const navigation = useNavigate();

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    const dep = await AuthAPIs.getDepartments();
    if (dep) {
      const customArr = [];
      const arr = [...dep.data.data];
      arr.forEach((item) => {
        const data = { value: item?.id, label: item?.name };
        customArr.push(data);
      });
      setDepartments(customArr);
    }
  };

  const options = [
    { value: "Criminal Record Check", label: "Criminal Record Check" },
    { value: "Drivers Abstract", label: "Drivers Abstract" },
    { value: "Drivers Licence", label: "Drivers Licence" },
  ];

  const userOptions = [{ value: "user", label: "user" }];

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Login name is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least eight characters long"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    provience: Yup.string().required("Provience is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^[0-9]+$/, "Phone must contain only digits")
      .min(8, "Phone must be at least eight characters long"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required."),
    emergencyPhoneNumber: Yup.string().required("Emergency Phone Number is required"),
    dob: Yup.date().required("DOB is required"),
    department: Yup.string().required("Depatment is required"),
    documentType: Yup.string().required("Document Type is required"),
  });

  const submitHandler = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
      phoneNumber: values.phone,
      address: values.address,
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      city: values.city,
      postalCode: values.postalCode,
      provience: values.provience,
      documentType: values.documentType,
      emergencyPhoneNumber: values.emergencyPhoneNumber,
      dob: moment(values.dob).format('YYYY-MM-DD'),
      isActive: values.isActive,
      department: values.department,
    };

    const res = await AuthAPIs.registerUser(data);
    if (res) {
      toast.success("User Registered Successfully");
      if (uploadFilesAPI) {
        uploadFilesAPI?.map(async (item) => {
          await uploadFileHandler(res?.data?.data?.id, item);
        });
      }
      navigate("/dashboard/user-setup");
    }
  };

  const uploadFileHandler = async (user_id, file) => {
    const data = new FormData();
    data.append("attachments", file);
    const uploaded = await FileAPIs.uploadFileWithRole("user", user_id, data);
  };

  const appendFileInAPIState = () => {
    if (selectedFile) {
      selectedFile["customId"] = documentId;
      setUploadFileAPI((prev) => [...prev, selectedFile]);
      setSelectedFile(null);
      setDocumentId(documentId + 1);
    } else {
      toast.error("No File Selected");
    }
  };

  const fileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const allowedFormats = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "pdf",
        "docs",
        "txt",
        "docx",
        "xls",
        "xlsx",
      ];

      if (allowedFormats.includes(extension)) {
        setSelectedFile(file);
      } else {
        console.error("Invalid file format. Please choose a valid file.");
        toast.error("Invalid file format. Please choose a valid file.");
      }
    }
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  const deleteFile = (customId) => {
    const indexToDelete = uploadFilesAPI.findIndex(
      (item) => item.customId === customId
    );
    if (indexToDelete !== -1) {
      const updatedFilesList = [...filesList];
      updatedFilesList.splice(indexToDelete, 1);
      setFilesList(updatedFilesList);
    }
  };

  return (
    <>
      <section>
        <Heading text={"Manage User"} />

        <Card>
          <Formik
            onSubmit={(values) => {
              submitHandler(values);
            }}
            initialValues={{
              email: "",
              password: "",
              username: "",
              firstName: "",
              lastName: "",
              address: "",
              city: "",
              postalCode: "",
              provience: "",
              phone: "",
              emergencyPhoneNumber: "",
              dob: null,
              isActive: false,
              department: "",
              documentType: options[0].label,
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                className="dashboard-form"
                noValidate
                onSubmit={handleSubmit}
              >
                {/* <Row>
                  <Col className="column">
                    <Form.Group className="form-group">
                      <Form.Label>Users</Form.Label>
                      <Select
                        options={userOptions}
                        styles={customStyles}
                        defaultValue={userOptions[0]}
                        isDisabled
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    className="column"
                    style={{ maxWidth: "242px", marginTop: "28px" }}
                  >
                    <Button
                      className="me-3"
                      onClick={() => navigation("/dashboard//user-setup/edit")}
                    >
                      <FaRegEdit />
                      Edit
                    </Button>
                    <Button>
                      <AiOutlinePlus />
                      Add
                    </Button>
                  </Col>
                </Row> */}
                <Row>
                  {/* <Col md={12} className="my-3">
                    <Form.Label style={{ fontSize: "14px", color: "#000" }}>
                      Employee Number <span style={{ color: "#757575" }}>0</span>
                    </Form.Label>
                  </Col> */}
                  <Col md={6}>
                    <Form.Group
                      controlId="usernameGroup"
                      className="form-group"
                    >
                      <Form.Label htmlFor="username">Login Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"username"}
                        id={"username"}
                        placeholder="Kharshad"
                        value={values.username}
                        required
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"password"}
                        name={"password"}
                        placeholder="************"
                        value={values.password}
                        required
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"firstName"}
                        placeholder="Kharshad"
                        value={values.firstName}
                        required
                        isInvalid={!!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"lastName"}
                        placeholder="Arshad"
                        value={values.lastName}
                        required
                        isInvalid={!!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"address"}
                        placeholder="950 Portage Parkway"
                        value={values.address}
                        required
                        isInvalid={!!errors.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"city"}
                        value={values.city}
                        placeholder={"Calgary"}
                        required
                        isInvalid={!!errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"postalCode"}
                        placeholder={"54000"}
                        value={values.postalCode}
                        required
                        isInvalid={!!errors.postalCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>{" "}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Provience</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"text"}
                        name={"provience"}
                        placeholder={"Ontario"}
                        value={values.provience}
                        required
                        isInvalid={!!errors.provience}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.provience}
                      </Form.Control.Feedback>{" "}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"tel"}
                        name={"phone"}
                        value={values.phone}
                        required
                        placeholder={"03223247819"}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>{" "}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"email"}
                        name={"email"}
                        placeholder={"khizer@gmail.com"}
                        value={values.email}
                        required
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>Emergency Phone Number</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        type={"tel"}
                        name={"emergencyPhoneNumber"}
                        placeholder={"03223247819"}
                        value={values.emergencyPhoneNumber}
                        required
                        isInvalid={!!errors.emergencyPhoneNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emergencyPhoneNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label>DOB</Form.Label>
                      <div className="datepicker_box">
                        <CustomDatePicker
                          selected={values.dob}
                          onSelect={(date) =>
                            handleChange({
                              target: { name: "dob", value: date },
                            })
                          }
                          value={values.dob}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="MM/dd/yyyy"
                          maxDate={new Date()}
                        />
                        <span>
                          <MdDateRange />
                        </span>
                      </div>
                      {errors.dob && <span className="errorShow">{errors.dob}</span>}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <div className="checkboxHolder">
                        <Form.Check
                          type="checkbox"
                          id="isActive"
                          label="Active/Deactive"
                          name="isActive"
                          checked={values.isActive || false}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Form.Group className="form-group">
                      <Form.Label>Department</Form.Label>
                      <Select
                        options={departments}
                        styles={customStyles}
                        defaultValue={departments[0]}
                        value={departments.find(
                          (dep) => dep.value === values.department
                        )}
                        onChange={(selectedOption) => {
                          handleChange("department")(selectedOption.value);
                        }}
                      />
                      <p className="errorShow"> {errors.department}</p>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Button type="submit" className="me-3">
                      <IoSaveOutline />
                      Save
                    </Button>
                    {/* <Button>
                      <MdDeleteOutline />
                      Delete
                    </Button> */}
                  </Col>
                  <Col>
                    <hr
                      style={{ margin: "20px 0", border: "1px solid #ccc" }}
                    />
                  </Col>
                  {/* <Col sm={12} className="mb-2">
                    <Form.Group className="form-group">
                      <Form.Label>Document Type</Form.Label>
                      <Select
                        options={options}
                        styles={customStyles}
                        defaultValue={options[0]}
                        value={options.find(
                          (dep) => dep.value === values.documentType
                        )}
                        onChange={(selectedOption) => {
                          handleChange("documentType")(selectedOption.value);
                        }}
                      />
                    </Form.Group>
                    <p className="errorShow"> {errors.documentType}</p>
                  </Col>
                  <Col className="column">
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label htmlFor="fileInput" className="browse-button">
                        <Button onClick={handleBrowseClick}>Browse</Button>
                        {selectedFile?.name}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="fileInput"
                        className="d-none"
                        onChange={fileInputChange}
                        accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .txt, .xls, .xlsx"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mb-4 column" style={{ maxWidth: "145px" }}>
                    <Button
                      style={{ minHeight: "44px" }}
                      onClick={appendFileInAPIState}
                    >
                      <BiUpload />
                      Upload
                    </Button>
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col>
                    <ul className="documentList">
                      <li>
                        <h6>Document</h6>
                      </li>
                      {uploadFilesAPI[0] ? (
                        uploadFilesAPI?.map((item, ind) => {
                          return (
                            <li>
                              <a href={item?.path} target="_blank">
                                {" "}
                                <h5>{item.name}</h5>
                              </a>
                              <Button
                                style={{
                                  minHeight: "35px",
                                  padding: "0 8px",
                                  lineHeight: "35px",
                                }}
                              >
                                <span className="no-margin">
                                  <FaTimes
                                    onClick={() => {
                                      deleteFile(item?.customId);
                                    }}
                                  />
                                </span>
                              </Button>
                            </li>
                          );
                        })
                      ) : (
                        <p className="text-center pt-3">
                          No Documents Uploaded
                        </p>
                      )}
                    </ul>
                  </Col>
                </Row> */}
              </Form>
            )}
          </Formik>
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default AddUser;
