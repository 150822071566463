import React from "react";
import classes from "./index.module.scss";

const AuthLayout = ({ children }) => {
  return (
    <div id={classes.wrapper}>
   
      <main id={classes.main}>
          {children}
      </main>
    </div>
  );
};

export default AuthLayout;
