import React, { useState, memo, useEffect } from "react";
import { Table } from "react-bootstrap";

import HourLogAPIs from "../../../../APIs/hour-log";
import { format } from "date-fns";
import { UNITS_INITIAL_COLUMNS } from "config/constants";

const PayrollTicketSummaryTab = memo(({ logDate, selectedUserId }) => {
  const [tickets, setTickets] = useState([]);

  const fetchSummaryLogs = async () => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getTicketSummary(
        format(new Date(logDate), "yyyy-MM-dd"),
        selectedUserId
      );
      if (response && response.data && response.data.data.length > 0) {
        setTickets(response?.data?.data);
      } else {
        setTickets([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setTickets([]);
    }
  };

  useEffect(() => {
    fetchSummaryLogs();
  }, [logDate]);

  // Function to format column names
  const formatColumnName = (columnName) => {
    return columnName
      .replace(/([A-Z]+)/g, " $1")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (letter) => letter.toUpperCase())
      .trim();
  };

  // Function to format cell values
  const formatValue = (ticket, columnName) => {
    if (columnName === "stationCodes" && Array.isArray(ticket[columnName])) {
      return ticket[columnName].map((sc) => sc.name).join(", ");
    }
    if (columnName.includes("Unit")) {
      return ticket.ticketPreComplete && ticket.ticketPreComplete[columnName]
        ? ticket.ticketPreComplete[columnName]
        : "0";
    }
    if (columnName === "isAssignPerTicket") {
      return ticket.logType === "PER_TICKET" ? "Yes" : "No";
    }
    return ticket[columnName] === null || ticket[columnName] === undefined
      ? "0"
      : ticket[columnName];
  };

  // Determine which columns to display, including a check for 'stationCodes'
  const columns = tickets.reduce((acc, ticket) => {
    Object.keys(ticket).forEach((key) => {
      if (
        key === "ticketNo" ||
        key === "stationCodes" ||
        key === "priority" ||
        key === "isAssignPerTicket"
      ) {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      }
    });
    if (ticket.ticketPreComplete) {
      Object.keys(ticket.ticketPreComplete).forEach((key) => {
        if (key.includes("Unit") && !acc.includes(key)) acc.push(key);
      });
    }
    return acc.some((item) => item.includes("Unit"))
      ? acc
      : [...acc, ...UNITS_INITIAL_COLUMNS];
  }, []);

  return (
    <div>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{formatColumnName(column)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => (
            <tr key={ticket.id || index}>
              {columns.map((column) => (
                <td key={column}>{formatValue(ticket, column)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
});

export default PayrollTicketSummaryTab;
