export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const KEY =
  "YW1Gb1lXNTZZV2xpTG1GemJHRnRMbTFsYUdGeVFHZHRZV2xzTG1OdmJUb3lZV2RoYVc0PQ==";

export const ENDPOINT = {
  auth: {
    login: `${API_URL}/auth/login`,
    department: `${API_URL}/auth/departments`,
    registerUser: `${API_URL}/auth/sign-up`,
    permissions: `${API_URL}/auth/permissions`,
  },

  users: {
    getAll: `${API_URL}/user`,
    getUserByID: `${API_URL}/user/userById`,
    getAllIds: `${API_URL}/user/all`,
    updateUser: `${API_URL}/user/update/`,
  },

  file: {
    uploadFileWithRole: `${API_URL}/files/upload`,
    uploadFile: `${API_URL}/user`,
    getFiles: `${API_URL}/user/files`,
    deleteFile: `${API_URL}/files`,
  },

  tickets: {
    create: `${API_URL}/ticket`,
    search: `${API_URL}/ticket/search?`,
    searchTicket: `${API_URL}/searchTicket/`,
    assignReassign: `${API_URL}/ticket/assign_reassign`,
    preComplete: `${API_URL}/ticket/pre-complete`,
    complete: `${API_URL}/ticket/complete`,
    uncomplete: `${API_URL}/ticket/un-complete`,
    cancel: `${API_URL}/ticket/cancel`,
    officeClear: `${API_URL}/ticket/office_clear`,
    stats: `${API_URL}/ticket/stats`,
    outstandingTickets: `${API_URL}/ticket/outstanding-tickets`,
    close: `${API_URL}/ticket/close`,
    logs: `${API_URL}/ticket/ticket_logs`,
    addLog: `${API_URL}/ticket/add-log`,
    getFiles: `${API_URL}/ticket/files`,
    uploadFile: `${API_URL}/ticket`,
    sendToLocate: `${API_URL}/ticket/send-to-locate`,
    sendEmail: `${API_URL}/ticket/send-email`,
    preCompleteDetail: `${API_URL}/ticket-pre-complete`,
    deletePreCompleteDetail: `${API_URL}/ticket-pre-complete`,
    officeClearStation: `${API_URL}/ticket-office-clear`,
    getOfficeClearStationCode: `${API_URL}/ticket-office-clear/by-ticket`,
    getTicketPreComplete: `${API_URL}/ticket-pre-complete/by-ticket`,
    addLog: `${API_URL}/ticket/add-log`,
    rescheduleTicket: `${API_URL}/ticket/reschedule`,
    revokeTicket: `${API_URL}/ticket/revoke`,
    nonCompliantReport: `${API_URL}/ticket/non-compliant-report`,
    adminDashboardStats: `${API_URL}/ticket/dashboard-stats`,
    closedTickets: `${API_URL}/ticket/closed-tickets`,
    synchronizeTickets: `${API_URL}/ticket/move-from-processing-to-pending`,
    reinstateTicket: `${API_URL}/ticket/re-instate-ticket`,
    fixCompliance: `${API_URL}/ticket/exclude-holidays`,
  },
  city: {
    create: `${API_URL}/cities`,
    get: `${API_URL}/cities`,
    getByid: `${API_URL}/cities`,
    update: `${API_URL}/cities`,
    delete: `${API_URL}/cities`,
  },
  department: {
    create: `${API_URL}/departments`,
    get: `${API_URL}/departments`,
    getByid: `${API_URL}/departments`,
    update: `${API_URL}/departments`,
    delete: `${API_URL}/departments`,
  },
  stationcode: {
    create: `${API_URL}/station-codes`,
    get: `${API_URL}/station-codes`,
    getByid: `${API_URL}/station-codes`,
    update: `${API_URL}/station-codes`,
    delete: `${API_URL}/station-codes`,
  },
  doctype: {
    create: `${API_URL}/doc-types`,
    get: `${API_URL}/doc-types`,
    getByid: `${API_URL}/doc-types`,
    update: `${API_URL}/doc-types`,
    delete: `${API_URL}/doc-types`,
  },
  reason: {
    create: `${API_URL}/reasons`,
    get: `${API_URL}/reasons`,
    getByid: `${API_URL}/reasons`,
    update: `${API_URL}/reasons`,
    delete: `${API_URL}/reasons`,
  },
  region: {
    create: `${API_URL}/region`,
    get: `${API_URL}/region`,
    getByid: `${API_URL}/region`,
    update: `${API_URL}/region`,
    delete: `${API_URL}/region`,
  },
  tickettype: {
    create: `${API_URL}/ticket-types`,
    get: `${API_URL}/ticket-types`,
    getByid: `${API_URL}/ticket-types`,
    update: `${API_URL}/ticket-types`,
    delete: `${API_URL}/ticket-types`,
  },
  hourlog: {
    addHourLog: `${API_URL}/hour-logs/add-hour-log`,
    getHourLog: `${API_URL}/hour-logs`,
    getQueryHourLog: `${API_URL}/hour-logs/query-logs`,
    getReimbursements: `${API_URL}/hour-logs/reimbursements`,
    uploadReimbursement: `${API_URL}/hour-logs/add-reimbursement`,
    deleteHourLog: `${API_URL}/hour-logs/delete-hour-log-detail`,
    updateHourLog: `${API_URL}/hour-logs/update-hour-log-detail`,
    getTicketSummary: `${API_URL}/hour-logs/ticket-summary`,
    getPayrollReport: `${API_URL}/hour-logs/payroll-report`,
    getHoursLogTicketReport: `${API_URL}/hour-logs/generate-hour-log-tickets-report`,
    getHoursLogPerTicketReport: `${API_URL}/hour-logs/generate-hour-log-per-tickets-report`,
    checkTicketStatus: `${API_URL}/hour-logs/check-ticket-status`,
    updateApproval: `${API_URL}/hour-logs/update-approval`,
    getBillingReport: `${API_URL}/hour-logs/billing-report`,
    deleteReimbursement: `${API_URL}/hour-logs/delete-reimbursement`,
  },
  officeClear: {
    statsData: `${API_URL}/ticket-office-clear/office-clear-tickets-stats`,
  },
};

export const UNITS_INITIAL_COLUMNS = [
  "bramptonStormUnit",
  "catvUnit",
  "electricalUnit",
  "gasUnit",
  "hydroUnit",
  "otherUnit",
  "peelStormUnit",
  "sanitaryUnit",
  "sewerUnit",
  "streetLightUnit",
  "telecomUnit",
  "trafficLightUnit",
  "waterUnit",
  "zumUnit",
];
