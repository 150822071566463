import React, { useState, useCallback, memo, useEffect } from "react";
import { Button, Form, Table, Row, Col } from "react-bootstrap";

import HourLogAPIs from "../../../../APIs/hour-log";
import { toast } from "react-toastify";
import { format } from "date-fns";

const ReimbursementTab = memo(({ logDate }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);

  const [expenseType, setExpenseType] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState(null);
  const [userId, setUserId] = useState("");

  const fileInputRef = React.useRef(null);

  const reimbursementOptions = [
    { label: "Select Type", value: "" },
    { label: "Gas", value: "Gas" },
    { label: "VehicleMaintenance", value: "Vehicle Maintenance" },
    { label: "Equipment", value: "Equipment" },
    { label: "Accessories", value: "Accessories" },
    { label: "Other", value: "Other" },
  ];

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const fetchReimbursements = async (userId) => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getReimbursements(
        format(new Date(logDate), "yyyy-MM-dd"),
        userId
      );
      if (response && response.data && response.data.data.length > 0) {
        setReimbursements(response.data.data);
      } else {
        setReimbursements([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setReimbursements([]);
    }
  };

  const handleFileChange = useCallback((event) => {
    // only allow jpg, jpeg, png files
    if (!event?.target?.files[0]?.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      toast.error(".jpg, .jpeg, .png, .pdf files are allowed");
      return;
    }
    setFile(event.target.files[0]);
  }, []);
  const handleFileRemove = () => {
    setFile(null);
    document.getElementById("fileInput").value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !expenseType || !amount) {
      console.log("file", file, "expenseType", expenseType, "amount", amount);
      toast.error("Please fill all the fields and select a file");
      return;
    }
    setLoading(true);

    try {
      // Assuming you have a function to upload the file and submit the form data
      const formData = new FormData();
      formData.append("file", file);
      formData.append("expenseType", expenseType);
      formData.append("date", format(new Date(logDate), "yyyy-MM-dd")); // Or another date field if necessary
      formData.append("amount", amount);

      const response = await HourLogAPIs.uploadReimbursement(formData);
      if (response.data.success) {
        toast.success("Reimbursement submitted successfully");
        setFile(null);
        setExpenseType("");
        setAmount("");
        // Refresh or update reimbursements list if necessary
        await fetchReimbursements(userId);
      } else {
        // toast.error("Failed to submit reimbursement");
      }
    } catch (error) {
      console.error("Error submitting reimbursement:", error);
      //toast.error("Error submitting reimbursement");
    } finally {
      setLoading(false); // Re-enable the button after the response
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    fetchReimbursements(user?.id);
    setUserId(user?.id);
  }, [logDate]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        // onSubmit={(e) => {
        //   // disbale this action for now
        //   alert(
        //     `Uploding reimbursements are not available at the moment, we are working on it, it will be available soon`
        //   );
        //   e.preventDefault();
        // }}
      >
        <Row className="align-items-center my-3">
          <Col md={3}>
            <Form.Label>Expense Type</Form.Label>
            <Form.Select
              aria-label="Select type"
              value={expenseType}
              onChange={(e) => setExpenseType(e.target.value)}
            >
              {reimbursementOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Form.Label>Upload Receipt</Form.Label>
            <br />
            <Button
              type="button"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Upload
            </Button>
            <Form.Control
              type="file"
              id="fileInput"
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Col>
          <Col md={3}>
            <Button variant="success" type="submit" disabled={loading}>
              {loading ? "Uploading..." : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          marginLeft: "30px",
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginRight: "10px",
            padding: "5px 5px",
            fontWeight: "bold",
            color: `${file ? "black" : "red"}`,
          }}
        >
          {file ? file?.name : "No file selected"}
        </span>
        {file && (
          <h5
            onClick={handleFileRemove}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          >
            X
          </h5>
        )}
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Price</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reimbursements.map((item, index) => (
            <tr key={index}>
              <td>
                {item?.files[0]?.presignedUrl ? (
                  <a
                    href={item.files[0].presignedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.files[0].description}
                  </a>
                ) : (
                  "No Link"
                )}
              </td>
              <td>{item.amount}</td>
              <td>{item.expenseType}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this reimbursement?"
                      )
                    ) {
                      try {
                        const response = await HourLogAPIs.deleteReimbursement({
                          reimbursementId: item.id,
                        });
                        if (response.data.success) {
                          toast.success("Reimbursement deleted successfully");
                          await fetchReimbursements(userId);
                        } else {
                          toast.error("Failed to delete reimbursement");
                        }
                      } catch (error) {
                        console.error("Error deleting reimbursement:", error);
                        toast.error("Error deleting reimbursement");
                      }
                    }
                  }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
});

export default ReimbursementTab;
