import API from "APIs/base";
import { ENDPOINT } from "config/constants";

const uploadFileWithRole = async (role, userId, file) => {
  return await API.postMethod(
    `${ENDPOINT.file.uploadFileWithRole}/${role}/${userId}/upload-attachments`,
    false,
    file,
    false
  );
};

const uploadFile = async ( id, file) => {
  return await API.postMethod(
    `${ENDPOINT.tickets.uploadFile}/${id}/upload-files`,
    true,
    file,
    true
  );
};

const deleteFile = async (id) => {
  return await API.deleteMethod(`${ENDPOINT.file.deleteFile}/${id}`, true);
};

const getFiles = async (data) => {
  return await API.postMethod(`${ENDPOINT.file.getFiles}`, true, data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadFileWithRole,
  uploadFile,
  deleteFile,
  getFiles,
};
