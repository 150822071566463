import React from 'react'
import { IoIosWarning } from "react-icons/io";


const Blocked = () => {
    return (
        <>
            <div className='blockError'>
                <IoIosWarning />
                <h3>YOU CAN NOT ACCESS THIS PAGE.</h3>
            </div>
        </>
    )
}

export default Blocked
