import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Heading from "Components/Heading";
import { RxUpload } from "react-icons/rx";
import Select from "react-select";
import AuthAPIs from "../../APIs/auth";

import { toast } from "react-toastify";
import Loader from "Components/Loader";
import { capitalizeFirstLetter } from "Helper/Converters";
import PermissionTable from "./components/PermissionTable";

const DepartmentPermisiion = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [permissionsUpdated, setPermissionsUpdated] = useState();

  const handleCheckboxChange = (permissionId, isChecked) => {
    if (isChecked) {
      // Add permissionId to checkedItems if it's not already included
      setCheckedItems((prevItems) => [...prevItems, permissionId]);
    } else {
      // Remove permissionId from checkedItems if unchecked
      setCheckedItems((prevItems) =>
        prevItems.filter((item) => item !== permissionId)
      );
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    getDepartments();
  }, [permissionsUpdated]);

  const getDepartments = async () => {
    setIsLoading(true);
    const dep = await AuthAPIs.getDepartments();
    if (dep) {
      const customArr = [];
      const arr = [...dep.data.data];
      arr.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${capitalizeFirstLetter(item?.name)}`,
          permissions: item?.permissions,
        };
        customArr.push(data);
      });
      setDepartments(customArr);
    }
    setIsLoading(false);
  };

  const handleUserSelect = async (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption.permissions && selectedOption.permissions[0]) {
      setCheckedItems(selectedOption.permissions);
    } else {
      setCheckedItems([]);
    }
  };

  const submitHandler = async () => {
    setIsLoading(true);
    if (selectedUser) {
      const data = {
        department_id: selectedUser.value,
        permissions: checkedItems,
      };
      const update = await AuthAPIs.permissions(data);
      if (update) {
        toast.success("Permissions Updated Successfully.");
        setPermissionsUpdated(update);
        setCheckedItems([]);
      }
    } else {
      toast.error("Department is not selected.");
    }
    setIsLoading(false);
  };

  const permissionData = [
    {
      id: "1",
      permission: "Alert Messages",
      permission_id: "alert_messages",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="alert_messages"
          name="alert_messages"
          checked={checkedItems.includes("alert_messages") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "2",
      permission: "Assign Tickets",
      permission_id: "assign_ticket",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="assign_ticket"
          name="assign_ticket"
          checked={checkedItems.includes("assign_ticket") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "3",
      permission: "Assign Ticket By Map",
      permission_id: "assign_tickey_by_map",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="assign_tickey_by_map"
          name="assign_tickey_by_map"
          checked={checkedItems.includes("assign_tickey_by_map") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "4",
      permission: "Billing",
      permission_id: "billing",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="billing"
          name="billing"
          checked={checkedItems.includes("billing") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "5",
      permission: "Query/Search Ticket",
      permission_id: "query_search_ticket",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="query_search_ticket"
          name="query_search_ticket"
          checked={checkedItems.includes("query_search_ticket") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "6",
      permission: "Office Clear",
      permission_id: "office_clear",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="office_clear"
          name="office_clear"
          checked={checkedItems.includes("office_clear") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "7",
      permission: "Close Tickets",
      permission_id: "close_tickets",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="close_tickets"
          name="close_tickets"
          checked={checkedItems.includes("close_tickets") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "8",
      permission: "Outstanding Tickets",
      permission_id: "outstanding_tickets",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="outstanding_tickets"
          name="outstanding_tickets"
          checked={checkedItems.includes("outstanding_tickets") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "9",
      permission: "Reassign Tickets",
      permission_id: "reassign_tickets",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="reassign_tickets"
          name="reassign_tickets"
          checked={checkedItems.includes("reassign_tickets") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "10",
      permission: "Create New Tickets",
      permission_id: "create_new_tickets",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="create_new_tickets"
          name="create_new_tickets"
          checked={checkedItems.includes("create_new_tickets") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "11",
      permission: "Hours Log",
      permission_id: "hours_log",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="hours_log"
          name="hours_log"
          checked={checkedItems.includes("hours_log") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "12",
      permission: "Payroll Audit",
      permission_id: "payroll_audit",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="payroll_audit"
          name="payroll_audit"
          checked={checkedItems.includes("payroll_audit") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "13",
      permission: "User Setup",
      permission_id: "user_setup",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="user_setup"
          name="user_setup"
          checked={checkedItems.includes("user_setup") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "14",
      permission: "Department Permission",
      permission_id: "department_permissions",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="department_permissions"
          name="department_permissions"
          checked={checkedItems.includes("department_permissions") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "15",
      permission: "Non Compliance Report",
      permission_id: "non_compliance_report",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="non_compliance_report"
          name="non_compliance_report"
          checked={checkedItems.includes("non_compliance_report") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
    {
      id: "16",
      permission: "Content Managment",
      permission_id: "content_management",
      date: "May-24-2023",
      checkbox: (
        <Form.Check
          type="checkbox"
          id="content_management"
          name="content_management"
          checked={checkedItems.includes("content_management") || false}
          onChange={handleCheckboxChange}
          className="include-check mb-3 d-flex align-items-center justify-content-end gap-1"
        />
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Department Permission"} />
          <Card>
            <Form className="dashboard-form mb-4">
              <Row>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      options={departments}
                      styles={customStyles}
                      defaultValue={departments[0]?.label}
                      value={departments?.label}
                      onChange={handleUserSelect}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <PermissionTable
              permissionData={permissionData}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
            <Row>
              <Col className="my-3">
                <Button onClick={submitHandler} disabled={!checkedItems[0]}>
                  <RxUpload /> Update
                </Button>
              </Col>
            </Row>
          </Card>
        </section>
      )}
    </>
  );
};

export default DepartmentPermisiion;
