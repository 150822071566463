import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import TicketAPIs from "../../../APIs/ticket";
import { toast } from "react-toastify";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";

const PreCompleteModal = ({ showModal, handleClose, ticketId, userId }) => {

  const [existingData, setExistingData] = useState({})
  // Handling checkbox changes
  const handleCheckboxChange = (stateUpdater, value) => {
    stateUpdater((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };
  // Field marking options
  const fieldMarkingOptions = ["Paint", "Stakes", "Flags", "Others"];

  // Utilities marked options
  const utilitiesMarkedOptions = [
    "Gas",
    "Hydro Telecom",
    "CATV",
    "Water",
    "Sewer",
    "Other",
    "Street Light",
    "Traffic Light",
    "ZUM Sanitary",
    "Brampton Storm",
    "Peel Storm",
    "Electrical",
  ];

  const validationSchema = Yup.object().shape({
    locateVoid: Yup.string().required("This field is required"),
    cautionMessage: Yup.string().required("This field is required"),
    locatorComments: Yup.string().required("This field is required"),
    gasUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    hydroTelecomUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    catvUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    waterUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    sewerUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    otherUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    streetLightUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    trafficLightUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    zumSanitaryUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    bramptonStormUnit: Yup.number().min(
      0,
      "Must be greater than or equal to 0"
    ),
    peelStormUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
    electricalUnit: Yup.number().min(0, "Must be greater than or equal to 0"),
  });

  const getTicketPreCompleteData = async () => {
    if (ticketId) {
      const tickets = await TicketAPIs.getTicketPreCompleteData(ticketId);
      if (tickets?.data?.success) {
        setExistingData(tickets.data?.data?.[0] || {})
        // setPreCompleteInfo(tickets.data.data);
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      getTicketPreCompleteData()
    }
  }, [showModal])

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Formik
        enableReinitialize
        initialValues={{
          fieldMarking: existingData.fieldMarking ? existingData.fieldMarking.split(', ') : [],
          utilitiesMarked: [],
          locateVoid: existingData.locateVoid || "",
          cautionMessage: existingData.cautionMessage || "",
          locatorComments: existingData.locatorComments || "",
          dueDate: new Date(),
          dueTime: new Date(),
          gasUnit: existingData.gasUnit || "0",
          hydroUnit: existingData.hydroUnit || "0",
          telecomUnit: existingData.telecomUnit || "0",
          catvUnit: existingData.catvUnit || "0",
          waterUnit: existingData.waterUnit || "0",
          sewerUnit: existingData.sewerUnit || "0",
          otherUnit: existingData.otherUnit || "0",
          streetLightUnit: existingData.streetLightUnit || "0",
          trafficLightUnit: existingData.trafficLightUnit || "0",
          zumUnit: existingData.zumUnit || "0",
          sanitaryUnit: existingData.sanitaryUnit || "0",
          bramptonStormUnit: existingData.bramptonStormUnit || "0",
          peelStormUnit: existingData.peelStormUnit || "0",
          electricalUnit: existingData.electricalUnit || "0",
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (values) => {
          const submissionValues = {
            ...values,
            dueDate: moment(values.dueDate).format('YYYY-MM-DD'),
            fieldMarking: values.fieldMarking.join(", "),
            utilitiesMarked: values.utilitiesMarked.join(", "),
            ticketId: ticketId,
            userId: userId,
            gasUnit: String(values.gasUnit),
            hydroUnit: String(values.hydroUnit),
            telecomUnit: String(values.telecomUnit),
            catvUnit: String(values.catvUnit),
            waterUnit: String(values.waterUnit),
            sewerUnit: String(values.sewerUnit),
            otherUnit: String(values.otherUnit),
            streetLightUnit: String(values.streetLightUnit),
            trafficLightUnit: String(values.trafficLightUnit),
            zumUnit: String(values.zumUnit),
            sanitaryUnit: String(values.sanitaryUnit),
            bramptonStormUnit: String(values.bramptonStormUnit),
            peelStormUnit: String(values.peelStormUnit),
            electricalUnit: String(values.electricalUnit),
          };
          await TicketAPIs.deletePreCompleteTicketDetail(ticketId);
          const res = await TicketAPIs.preCompleteTicketDetail(
            submissionValues
          );
          if (res) {
            await TicketAPIs.preCompleteTicket({
              ticketId: [ticketId],
              userId: userId,
            });
            toast.success("Ticket Pre-Completed Successfully.");
            window.location.reload();
          } else {
            toast.success("Ticket Not Pre-Completed.");
          }
          //submitHandler(values);
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form
              className="dashboard-form mb-4"
              noValidate
              onSubmit={handleSubmit}
            >
              <Modal.Header closeButton>
                <Modal.Title>Pre-Completion Checklist</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Field Marking Checkboxes */}
                <Form.Group as={Row}>
                  <Form.Label as="legend" column sm={2}>
                    Field Marking
                  </Form.Label>
                  <Col sm={10}>
                    {fieldMarkingOptions.map((option, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={option}
                        name="fieldMarking"
                        id={`field-marking-${index}`}
                        value={option}
                        checked={values.fieldMarking.includes(option)}
                        onChange={handleChange}
                      />
                    ))}
                  </Col>
                </Form.Group>

                <Col md={12}>
                  <Form.Group controlId="gasUnit">
                    <Form.Label>Gas Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="gasUnit"
                      value={values.gasUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="hydroUnit">
                    <Form.Label>Hydro Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="hydroUnit"
                      value={values.hydroUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="telecomUnit">
                    <Form.Label>Telecom Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="telecomUnit"
                      value={values.telecomUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="catvUnit">
                    <Form.Label>CATV Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="catvUnit"
                      value={values.catvUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="waterUnit">
                    <Form.Label>Water Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="waterUnit"
                      value={values.waterUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="sewerUnit">
                    <Form.Label>Sewer Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="sewerUnit"
                      value={values.sewerUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="otherUnit">
                    <Form.Label>Other Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="otherUnit"
                      value={values.otherUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="streetLightUnit">
                    <Form.Label>Street Light Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="streetLightUnit"
                      value={values.streetLightUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="trafficLightUnit">
                    <Form.Label>Traffic Light Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="trafficLightUnit"
                      value={values.trafficLightUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="zumUnit">
                    <Form.Label>ZUM Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="zumUnit"
                      value={values.zumUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="sanitaryUnit">
                    <Form.Label>Sanitary Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="sanitaryUnit"
                      value={values.sanitaryUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="bramptonStormUnit">
                    <Form.Label>Brampton Storm Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="bramptonStormUnit"
                      value={values.bramptonStormUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="peelStormUnit">
                    <Form.Label>Peel Storm Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="peelStormUnit"
                      value={values.peelStormUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="electricalUnit">
                    <Form.Label>Electrical Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="electricalUnit"
                      value={values.electricalUnit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* Locate Void Input */}
                <Form.Group controlId="locateVoid">
                  <Form.Label>Locate Void</Form.Label>
                  <Form.Control
                    type="text"
                    name={"locateVoid"}
                    onChange={handleChange}
                    value={values.locateVoid}
                    required
                    isInvalid={!!errors.locateVoid}
                  />
                </Form.Group>

                {/* Caution Message Input */}
                <Form.Group controlId="cautionMessage">
                  <Form.Label>Caution Message</Form.Label>
                  <Form.Control
                    type="text"
                    name={"cautionMessage"}
                    onChange={handleChange}
                    value={values.cautionMessage}
                    required
                    isInvalid={!!errors.cautionMessage}
                  />
                </Form.Group>

                {/* Locator Comments Input */}
                <Form.Group controlId="locatorComments">
                  <Form.Label>Locator Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    name={"locatorComments"}
                    onChange={handleChange}
                    value={values.locatorComments}
                    required
                    isInvalid={!!errors.locatorComments}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Work To Be Finished By/Due Date</Form.Label>
                  <div className="datepicker_box">
                    <CustomDatePicker
                      showTimeSelect
                      selected={values.dueDate}
                      onSelect={(date) =>
                        handleChange({
                          target: { name: "dueDate", value: date },
                        })
                      }
                      value={values.dueTime}
                      dateFormat="MM/dd/yyyy HH:MM"
                    />
                    <span>
                      <MdDateRange />
                    </span>
                  </div>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  );
};

export default PreCompleteModal;
